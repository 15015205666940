import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBillingInfo } from '@/api/profile'
import { BillingInfoType } from '@/api/profile/schema'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { NavLink } from 'react-router-dom'

interface UpdateCardDetailsProps {
    businessProfileData: ProfileSelfResponseType
}

const UpdateCardDetails = ({ businessProfileData }: UpdateCardDetailsProps) => {
    const { data: billingInfoData } = useQuery<unknown, AxiosError, BillingInfoType>({
        queryKey: ['billingInfo', businessProfileData?.profileId],
        queryFn: () => getBillingInfo(businessProfileData?.profileId as string),
    })

    const user = useViewedAsUser()

    return (
        <section>
            <Label className='text-zentive-gray-medium'>Billing information</Label>
            <div className='w-full mb-[37px] pb-[16px] ring-1 rounded-[4px] ring-[#EBEBEB]'>
                <div className='flex justify-between mt-[6.5px] pt-[16.5px]'>
                    <div className='flex-col justify-start pl-[16px]'>
                        <p className='text-base font-semibold'>
                            {billingInfoData?.firstName ??
                                user?.firstName + ' ' + billingInfoData?.lastName ??
                                user?.lastName}
                        </p>
                        <p className='text-base text-zentive-gray-medium'>
                            {billingInfoData?.email ?? user?.email}
                        </p>
                    </div>

                    <div className='flex-col fjustify-start pr-[16px]'>
                        <div>
                            <button>
                                <NavLink
                                    className='text-zentive-green-dark mt-[16px]'
                                    to={`/owner-subscription/update-profile`}
                                >
                                    Update Billing Information
                                </NavLink>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpdateCardDetails
