import useViewedAsUser from '@/hooks/useViewedAsUser'
import { CreateSubscriptionDiscount } from './SubscriptionDiscount'
import { ROLE } from '@/constants'
import { CreateInvoiceDiscount } from './InvoiceDiscount'

export const CreateDiscount = () => {
    const user = useViewedAsUser()

    const isAdmin = user?.role?.roleName === ROLE.ADMIN

    return isAdmin ? <CreateSubscriptionDiscount /> : <CreateInvoiceDiscount />
}
