import { customerInvoiceAtom, expenseAtom, invoiceContentAtom } from '@/store/owner'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import ClientDialog from '../ClientModal'
import { jobGenerateInvoiceAtom, selectedJobForInvoiceAtom } from '@/store/job'
import { getJobsForInvoicing } from '@/api/invoicing'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { formatRecordNumber } from '@/utils/helper'
import { DEFAULT_ADDRESS, DEFAULT_QUOTE_VALUE, DROPDOWN_LABELS, RECORD_PREFIX, US_FORMAT } from '@/constants'
import { timezoneAtom, userAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'
import dayjs from 'dayjs'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { InvoiceType } from '@/api/invoicing/schema'
import { ReadJobType } from '@/api/job/schema'

const HeaderClientDetails = () => {
    const [isFocused, setIsFocused] = useState(false)
    const queryClient = useQueryClient()
    const customer = useAtomValue(customerInvoiceAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const [expenses, setExpenses] = useAtom(expenseAtom)
    const setJobForInvoicing = useSetAtom(selectedJobForInvoiceAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const user = useAtomValue(userAtom)
    const { setValue } = useFormContext<InvoiceType>()

    const { data: jobsForInvoicing } = useQuery({
        enabled: !!customer?.profile?.profileId,
        queryKey: ['jobsForInvoicing', customer?.profile?.profileId],
        queryFn: () => getJobsForInvoicing(customer?.profile?.profileId as string, user?.business?.businessId as string),
    })

    const handleOnChange = (job: string) => {
        setInvoiceContent(DEFAULT_QUOTE_VALUE)
        queryClient.invalidateQueries({ queryKey: ['nextEmpNumber'] })
        if (job === DROPDOWN_LABELS.jobsForInvoicing) {
            setJobForInvoicing(null)
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                totalExpense: 0.0,
            }))

            setInvoiceContent((prevVal) => ({
                ...prevVal,
                totalUnitPrice: 0.0,
            }))

            setInvoiceContent((prevVal) => ({
                ...prevVal,
                laborCost: 0.0,
            }))
        }
        const selectedJob: ReadJobType = JSON.parse(job)
        setJobForInvoicing(selectedJob)

        if (selectedJob?.quote?.expense?.length! > 0) {
            setExpenses([])
            setExpenses(selectedJob?.quote?.expense!)
        } else {
            setExpenses([])
        }

        const totalExpenses =
            expenses && expenses?.length > 0
                ? expenses.reduce((total, expense) => total + (expense.total || 0), 0)
                : 0

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalExpense: prevVal.totalExpense + totalExpenses,
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: selectedJob?.quote
                ? selectedJob?.quote?.productServiceGroup
                      ?.map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            internalNotes: selectedJob?.quote ? selectedJob?.quote?.internalNotes! : '',
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            laborCost: selectedJob?.quote?.laborCost ?? 0.0,
        }))

        if (selectedJob?.quote) {
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                subTotal:
                    prevVal.subTotal +
                    prevVal.totalExpense +
                    prevVal.totalProduct +
                    prevVal.totalService +
                    prevVal.laborCost,
            }))
        } else {
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                subTotal:
                    prevVal.totalExpense +
                    prevVal.totalProduct +
                    prevVal.totalService +
                    prevVal.laborCost,
            }))
        }
        
        setValue('businessId', user?.businessId ? user.businessId : '')
        setValue('status', 'draft')
        if (customer?.profile != null) {
            setValue('profileId', customer?.profile.profileId)
            setValue('profile', customer?.profile)
        }
        if (selectedJob?.quote != null) {
            setValue('quoteId', selectedJob?.quoteId)
            setValue('quote', selectedJob?.quote)
            setValue('quote.profile.address', selectedJob?.quote?.address ?? DEFAULT_ADDRESS)
            setValue('productServiceGroup', selectedJob?.quote?.productServiceGroup)
            setValue('expense', selectedJob?.quote?.expense)
            setValue('internalNotes', selectedJob?.quote?.internalNotes)
            setValue('laborCost', selectedJob?.quote?.laborCost)
            setValue('subTotal', selectedJob?.quote?.subTotal)
            setValue('totalAmount', selectedJob?.quote?.totalAmount as number)
            setValue('isDiscountPercent', selectedJob?.quote?.isDiscountPercent)
            setValue('discount', selectedJob?.quote?.discount)
            setValue('tax', Number(selectedJob?.quote?.tax?.toFixed(2)))
        }
    }

    const handleFocus = () => setIsFocused(true)

    const handleBlur = () => setIsFocused(false)
  
    useEffect(() => {
        if (customer) {
            setJobForInvoicing(null);
            setInvoiceContent(DEFAULT_QUOTE_VALUE)
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                totalExpense: 0.0,
            }))
            setValue('productServiceGroup', [])
        }
    }, [customer, setJobForInvoicing, setInvoiceContent]);

    return (
        <div className='mx-5 mt-6'>
            <div className='text-zentive-green-darker font-semibold text-2xl flex flex-row'>
                {generateInvoiceData
                    ? (generateInvoiceData?.quote?.profile?.firstName as string)
                    : customer?.profile.firstName}{' '}
                {generateInvoiceData
                    ? (generateInvoiceData?.quote?.profile?.lastName as string)
                    : customer?.profile.lastName}
                {customer ? <ClientDialog isEditing={true} /> : <ClientDialog isEditing={false} />}
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6 font-bold mb-4'>
                        <span className='font-bold text-base'>Property Address</span>
                    </div>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.streetAddress
                            : customer?.profile.address?.streetAddress}
                    </p>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.city
                            : customer?.profile.address?.city}
                        ,{' '}
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.state
                            : customer?.profile.address?.state}{' '}
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.zipCode
                            : customer?.profile.address?.zipCode}
                    </p>
                </div>
                <div className='flex flex-col mb-8'>
                    <span className=' pt-6 font-bold mb-4 text-base'>Contact Details</span>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.quote
                            ? generateInvoiceData.quote?.profile?.phoneNumber
                            : customer?.profile.phoneNumber}
                    </p>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.quote
                            ? generateInvoiceData.quote?.profile?.email
                            : customer?.profile.email}
                    </p>
                </div>
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6 font-bold mb-4'>
                        <span className='font-bold text-base'>Job</span>
                    </div>
                    <div className='relative z-10'>
                        <select
                            disabled={!customer?.profile}
                            name='row'
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            onChange={(val) => handleOnChange(val.target.value)}
                            className='flex 2xl:w-96 xl:w-60 lg:w-48 font-sans-pro text-base pl-3 pr-8 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm mr-4 bg-white appearance-none'
                        >
                            <option className='text-lg'>{DROPDOWN_LABELS.jobsForInvoicing}</option>

                            {jobsForInvoicing?.content?.map((data) => (
                                <option
                                    value={JSON.stringify(data)}
                                    key={data.jobNumber}
                                    className='text-lg'
                                >
                                    {`${RECORD_PREFIX.job}${formatRecordNumber(
                                        data.jobNumber ?? 0,
                                    )} - ${dayjs(data.appointment?.startDateTime)
                                        .tz(timezone.timeZoneId)
                                        .format(US_FORMAT)}`}
                                </option>
                            ))}
                        </select>
                        <div className='absolute inset-y-0 right-4 flex items-center pr-3 pointer-events-none'>
                            {isFocused ? (
                                <RiArrowDropUpLine className='h-5 w-5 text-gray-500' />
                            ) : (
                                <RiArrowDropDownLine className='h-5 w-5 text-gray-500' />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
