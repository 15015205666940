import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useToast } from '@/hooks/useToast'
import { useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { UpdateTaxRateStatusType } from '@/api/tax/schema'
import { updateTaxRateStatus } from '@/api/tax'
import { USER_STATUS } from '@/constants'

interface DeleteRestoreModalProps {
    taxRateId: string
}

const DeleteTaxRateModal: React.FC<DeleteRestoreModalProps> = ({ taxRateId }) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const [open, setOpen] = useState<boolean>(false)

    const { mutate: deleteTaxRateMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: UpdateTaxRateStatusType) => updateTaxRateStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['taxRates'] })
            toast({
                description: 'Tax Rate Deleted Successfully',
                variant: 'default',
                duration: 2000,
            })
        },
        onError: () => {
            toast({
                description: 'Error deleting co owner',
                variant: 'destructive',
                duration: 2000,
            })
        },
    })

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogTrigger asChild>
                <Button
                    variant={'ghost'}
                    // disabled={isDirty()
                    className='text-zentive-blue-dark hover:text-zentive-blue-dark/80 hover:bg-transparent'
                >
                    <FaRegTrashCan className='h-5 w-5 mt-4' />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Archive Tax Rate{' '}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Archived tax rates cannot be used when creating invoices.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to archive this tax rate?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                deleteTaxRateMu({
                                    taxRateId: taxRateId,
                                    status: USER_STATUS.DELETED,
                                })
                            }}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteTaxRateModal
