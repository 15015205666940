import { signOut } from '@/api/auth'
import { LAPTOP_MAX_WIDTH, ROLE, SUBSCRIPTION_TYPES } from '@/constants'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import {
    businessIdAtom,
    isViewedAsAtom,
    isViewedDismissedAtom,
    userAtom,
    viewedAsUserAtom,
} from '@/store/auth'
import { Popover, Transition } from '@headlessui/react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { ArrowLeftRightIcon, Circle, Clock } from 'lucide-react'
import { Fragment, useEffect, useState } from 'react'
import { BsPerson } from 'react-icons/bs'
import { CiCreditCard1, CiSettings } from 'react-icons/ci'
import { FaBars } from 'react-icons/fa'
import { FiChevronDown, FiLogOut } from 'react-icons/fi'
import { useMediaQuery } from 'react-responsive'
import { navAtom } from '../PrivateSideBar'
import Notifications from './Notifications'
import { RenderViewAsCancellationModal } from './ViewAsOwnerModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { freeTrialAtom, isFreeTrialEndedAtom } from '@/store/owner'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { getCustomerConnectedBusinesses } from '@/api/business'
import {
    CustomerConnectedBusinessListType,
    CustomerConnectedBusinessesType,
} from '@/api/business/schema'
import { useQuery } from '@tanstack/react-query'
import { cn, getFirstChar } from '@/utils/helper'
import { AvatarComponent } from '@/components/Avatar'
import { Avatar, AvatarFallback } from '@radix-ui/react-avatar'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { toast } from '@/hooks/useToast'
import { checkOwnerActiveStatus } from '@/api/profile'

export const Topbar = () => {
    const { navigateTo } = useAppNavigate()
    const [isViewedAsOwner, setIsViewedAsOwner] = useAtom(isViewedAsAtom)
    const [isViewedAsOwnerDismissed, setIsViewedAsOwnerDismissed] = useAtom(isViewedDismissedAtom)
    const currentUser = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const [owner, setOwner] = useAtom(viewedAsUserAtom)
    const [isProfileDisabled, setIsProfileDisalbed] = useState(false)
    const freeTrial = useAtomValue(freeTrialAtom)
    const [businessId, setBusinessId] = useAtom(businessIdAtom)
    const [isFreeTrialEnded, setIsFreeTrialEnded] = useAtom(isFreeTrialEndedAtom)
    const isViewedAsSubscriber = useAtomValue(isViewedAsAtom)
    const isFreeTrial =
        currentUser?.business?.subscriptionType?.name === SUBSCRIPTION_TYPES.FREE_TRIAL
    const navigate = useNavigate()

    const user = useViewedAsUser()

    const { pathname } = useLocation()

    const setSidebarOpen = useSetAtom(navAtom)

    const handleOwnerSubscriptionNavigation = () => navigateTo('/settings/owner-subscription')
    const handleProfileNavigation = () => navigateTo('/settings/profile')
    const handleSettingsNavigation = () => navigateTo('/settings/profile')

    const { data: businesses } = useQuery<CustomerConnectedBusinessListType>({
        queryKey: ['customerBusinesses', currentUser?.profileId as string],
        queryFn: () => getCustomerConnectedBusinesses(currentUser?.profileId as string),
        enabled: currentUser?.role?.roleName === ROLE.CUSTOMER,
    })

    //handle back to admin for owner view mode modal
    const handleCloseViewAsSubscriberModal = () => {
        setIsFreeTrialEnded(false)
        setOwner(RESET)
        setIsViewedAsOwner(RESET)
        navigateTo('/manage-subs/subscribers')
    }

    const handleUpgrade = () => {
        navigateTo('/owner-subscription/change-plan')
    }

    const handleSwitchBusiness = async (business: CustomerConnectedBusinessesType) => {
        const isActive = await checkOwnerActiveStatus(business?.businessId)
        if (isActive) {
            setBusinessId(business?.businessId)
        } else {
            toast({
                description: 'Account Restricted: Business no longer active',
                variant: 'destructive',
                duration: 2000,
            })
        }
    }

    const [image, setImage] = useState([
        {
            url:
                typeof currentUser?.profileImage === 'string'
                    ? currentUser?.profileImage
                    : undefined,
            name: `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`.trim(),
        },
    ])

    useEffect(() => {
        setImage([
            {
                url:
                    typeof currentUser?.profileImage === 'string'
                        ? `${currentUser?.profileImage}?${new Date().getTime()}`
                        : undefined,
                name: `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`.trim(),
            },
        ])
    }, [currentUser])

    useEffect(() => {
        setIsProfileDisalbed(isViewedAsOwner)
    }, [isViewedAsOwner])

    const handleDismissViewAsSubscriberModal = () => setIsViewedAsOwnerDismissed(false)

    const xl_vw_already = useMediaQuery({ maxWidth: LAPTOP_MAX_WIDTH })

    const shouldShowImage =
        (!isFreeTrialEnded &&
            owner?.business?.subscriptionType?.name !== SUBSCRIPTION_TYPES.FREE_TRIAL &&
            !isViewedAsSubscriber) ||
        (user?.business?.subscriptionType?.name === SUBSCRIPTION_TYPES.FREE_TRIAL &&
            isViewedAsOwner) ||
        user?.business?.subscriptionType?.name !== SUBSCRIPTION_TYPES.FREE_TRIAL ||
        user?.role?.roleName === ROLE.ADMIN

    return (
        <div>
            <div className='sticky text-zentive-gray-semi-medium top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
                {xl_vw_already && pathname !== '/customer-portal/create-password' && (
                    <button
                        type='button'
                        className='-m-2.5 p-2.5 text-gray-700 flex'
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className='sr-only'>Open sidebar</span>
                        <FaBars className='w-6 h-6' />
                    </button>
                )}

                <div
                    className={`flex flex-1 gap-x-4 lg:gap-x-6 ju ${
                        isFreeTrial &&
                        (currentUser?.role?.roleName === ROLE.OWNER ||
                            currentUser?.role?.roleName === ROLE.CO_OWNER) &&
                        !shouldShowImage
                            ? 'justify-between'
                            : 'justify-end'
                    }`}
                >
                    {!shouldShowImage && (
                        <div className='flex item-center justify-center'>
                            <button
                                onClick={() => {
                                    isFreeTrial && isFreeTrialEnded
                                        ? navigate('/owner-subscription/end-trial')
                                        : navigate('/dashboard')
                                }}
                            >
                                <img
                                    src={zentiveLogo}
                                    alt='Zentive Logo'
                                    className='h-[44px] w-[125px] cursor-pointer'
                                />
                            </button>
                        </div>
                    )}

                    {currentUser?.business?.subscriptionType?.name ===
                        SUBSCRIPTION_TYPES.FREE_TRIAL &&
                        (currentUser?.role?.roleName === ROLE.OWNER ||
                            currentUser?.role?.roleName === ROLE.CO_OWNER) && (
                            <>
                                {isFreeTrialEnded && currentUser.role.roleName !== ROLE.OWNER ? (
                                    <div className='flex items-center gap-x-1 lg:gap-x-2 bg-white relative'>
                                        <button
                                            onClick={() =>
                                                navigateTo('/owner-subscription/end-trial')
                                            }
                                        >
                                            <img
                                                src={zentiveLogo}
                                                alt='Zentive Logo'
                                                className='h-[44px] w-[125px] cursor-pointer'
                                            />
                                        </button>
                                    </div>
                                ) : (
                                    currentUser?.role?.roleName !== ROLE.CO_OWNER &&
                                    freeTrial &&
                                    freeTrial?.daysLeft > 0 && (
                                        <div className='flex items-center gap-x-1 lg:gap-x-2 bg-white relative'>
                                            <Clock color='#3B671A' />
                                            <div className='text-black'>
                                                {freeTrial?.daysLeft + 1} days left for free trial,
                                            </div>
                                            <button
                                                onClick={handleUpgrade}
                                                className='text-zentive-green-dark underline'
                                            >
                                                {' '}
                                                Upgrade Now?{' '}
                                            </button>
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    <div className='flex items-center gap-x-2 lg:gap-x-4'>
                        {!currentUser?.isPasswordGenerated && !isFreeTrialEnded && (
                            <Notifications />
                        )}
                        {/* to be refactored by interns */}
                        <Popover as='div' className='my-3 mx-3'>
                            <Popover.Button className='max-w-xs flex items-center text-sm focus:outline-none'>
                                <Avatar
                                    className='w-[48px] h-[48px] flex items-center justify-center'
                                    key={Math.random().toString(36)}
                                >
                                    <AvatarComponent
                                        user={currentUser ?? null}
                                        selectedImage={image}
                                        key={Math.random().toString(36)}
                                    />
                                    <AvatarFallback>
                                        {getFirstChar(currentUser?.firstName) +
                                            getFirstChar(currentUser?.lastName)}
                                    </AvatarFallback>
                                </Avatar>

                                <div className='inline-flex gap-2 justify-center items-center pl-5'>
                                    <div>
                                        <p className='font-semibold'>
                                            {currentUser!.firstName} {currentUser!.lastName}
                                        </p>
                                        <p className='mt-1 text-[#80A91D] text-xs capitalize'>
                                            {isViewedAsOwner
                                                ? 'View as (Owner)'
                                                : currentUser?.role?.roleName === ROLE.ADMIN
                                                  ? 'Super Admin'
                                                  : currentUser?.role?.roleName === ROLE.CO_OWNER
                                                    ? 'Co Owner'
                                                    : currentUser?.role?.roleName.toLocaleLowerCase()}
                                        </p>
                                    </div>
                                    {!currentUser?.isPasswordGenerated && (
                                        <FiChevronDown className='w-5 h-5' />
                                    )}
                                </div>
                            </Popover.Button>
                            {!currentUser?.isPasswordGenerated ? (
                                <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                >
                                    <Popover.Panel className='z-40 origin-top-right absolute right-4 top-16 w-[227px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                        <div className='flex font-[400] flex-col'>
                                            {!isFreeTrialEnded && (
                                                <button
                                                    onClick={() => null}
                                                    disabled={isViewedAsOwner}
                                                    type='button'
                                                    className='border-b-2 border-zentive-gray-bg'
                                                >
                                                    <div
                                                        className={`p-3 flex items-center ${
                                                            !isProfileDisabled
                                                                ? 'cursor-pointer text-sm rounded-t-md hover:bg-zentive-gray-bg hover:text-black hover:font-semibold transition-all'
                                                                : 'opacity-50 bg-zentive-gray-bg'
                                                        }`}
                                                    >
                                                        <BsPerson className='mr-3 w-6 h-6' />
                                                        <div
                                                            className='flex-1 text-left'
                                                            aria-disabled={isViewedAsOwner}
                                                            onClick={handleProfileNavigation}
                                                        >
                                                            <p className=''>
                                                                {currentUser!.firstName}{' '}
                                                                {currentUser!.lastName}
                                                            </p>
                                                            <p className='text-xs text-[#80A91D] capitalize'>
                                                                {isViewedAsOwner
                                                                    ? 'View as (Owner)'
                                                                    : currentUser?.role
                                                                            ?.roleName ===
                                                                        ROLE.ADMIN
                                                                      ? 'Super Admin'
                                                                      : currentUser?.role
                                                                              ?.roleName ===
                                                                          ROLE.CO_OWNER
                                                                        ? 'Co Owner'
                                                                        : currentUser?.role?.roleName.toLocaleLowerCase()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                            )}
                                            {(currentUser?.role?.roleName === ROLE.OWNER ||
                                                currentUser?.role?.roleName === ROLE.CO_OWNER) &&
                                                !isViewedAsOwner &&
                                                !isFreeTrialEnded && (
                                                    <button
                                                        onClick={handleSettingsNavigation}
                                                        type='button'
                                                        className='border-b-2 border-zentive-gray-bg'
                                                    >
                                                        <div className='p-3 flex items-center cursor-pointer text-sm hover:bg-zentive-gray-bg hover:text-black hover:font-semibold transition-all'>
                                                            <CiSettings className='mr-3 w-6 h-6' />
                                                            <div className='flex-1 text-left'>
                                                                <p> Settings </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                )}

                                            {currentUser?.role?.roleName === ROLE.OWNER &&
                                                !isViewedAsOwner && (
                                                    <button
                                                        onClick={handleOwnerSubscriptionNavigation}
                                                        type='button'
                                                        className='border-b-2 border-zentive-gray-bg'
                                                    >
                                                        <div className='p-3 flex items-center cursor-pointer text-sm hover:bg-zentive-gray-bg hover:text-black hover:font-semibold transition-all'>
                                                            <CiCreditCard1 className='mr-3 w-6 h-6' />
                                                            <div className='flex-1 text-left'>
                                                                <p> My Subscription</p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                )}

                                            {isViewedAsOwner && (
                                                <button
                                                    onClick={handleCloseViewAsSubscriberModal}
                                                    type='button'
                                                    className='border-b-2 border-zentive-gray-bg'
                                                >
                                                    <div className='p-3 flex items-center cursor-pointer text-sm hover:bg-zentive-gray-bg hover:text-black hover:font-semibold transition-all'>
                                                        <ArrowLeftRightIcon className='h-4' />
                                                        <div className='flex-1 text-left ml-3'>
                                                            <p> Switch Roles </p>
                                                        </div>
                                                    </div>
                                                </button>
                                            )}

                                            {currentUser?.role?.roleName === ROLE.CUSTOMER && (
                                                <div className='flex flex-col'>
                                                    {businesses?.map((business) => (
                                                        <button
                                                            key={business?.businessId}
                                                            className='flex flex-row justify-between px-4 text-left text-sm text-zentive-gray-dark p-2 hover:bg-zentive-gray-light/60'
                                                            onClick={() =>
                                                                handleSwitchBusiness(business)
                                                            }
                                                        >
                                                            <p>{business?.businessName}</p>
                                                            <Circle
                                                                className={cn(
                                                                    'h-3 w-3 fill-white border rounded-full border-zentive-green-dark mt-1',
                                                                    {
                                                                        'border-[.2rem]':
                                                                            businessId ===
                                                                            business?.businessId,
                                                                        'border-[.1rem]':
                                                                            businessId !==
                                                                            business?.businessId,
                                                                    },
                                                                )}
                                                            />
                                                        </button>
                                                    ))}
                                                </div>
                                            )}

                                            <button onClick={signOut} type='button'>
                                                <div className='p-3 flex items-center cursor-pointer text-sm rounded-b-md bg-zentive-gray-bg hover:bg-gray-300 hover:text-black hover:font-semibold transition-all'>
                                                    <FiLogOut className='mr-3 w-5 h-5 text-zentive-red-dark' />
                                                    <p className='flex-1 text-left'> Logout </p>
                                                </div>
                                            </button>
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            ) : null}
                        </Popover>
                    </div>
                </div>
            </div>
            <RenderViewAsCancellationModal
                isViewedAsOwner={isViewedAsOwner}
                owner={owner}
                isViewedAsOwnerDismissed={isViewedAsOwnerDismissed}
                handleCloseViewAsSubscriberModal={handleCloseViewAsSubscriberModal}
                handleDismissViewAsSubscriberModal={handleDismissViewAsSubscriberModal}
            />
        </div>
    )
}
