import { CreateSubscriptionRes } from '@/pages/private/OwnerSubscription/BillingInfo/UpdatePlan/ChangePlanConfirmation'
import { axiosInstance } from '../axiosInstance'
import {
    SubsByIntervalRequest,
    SubscriptionMatrix,
    SubscriptionType,
    SubSeatType,
    UpdateSubscriptionType,
} from './schema'
import { formatTo24HourClock } from '@/utils/time'
import { COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'

export const getStripeConnectStatus = async (
    profileId: string | undefined,
): Promise<{
    message: string
    status?: string
}> => {
    if (!profileId) throw new Error('Missing Profile ID')

    const response = await axiosInstance.get(`/stripe/check-status/${profileId}`)

    return response.data
}

export const getPricing = async (interval: string): Promise<SubscriptionType[]> => {
    const response = await axiosInstance.get(`/public/pricing/${interval}`)
    return response.data.content
}

export const getPricingById = async (pricingID: string): Promise<SubscriptionType> => {
    const response = await axiosInstance.get(`/public/plan/${pricingID}`)

    return response.data.content
}

export const getPricingIntervalById = async (
    data: SubsByIntervalRequest,
): Promise<SubscriptionType> => {
    const response = await axiosInstance.get(`/public/plan-by-interval`, {
        params: {
            subscriptionTypeId: data.planId,
            interval: data.interval,
        },
    })

    return response.data.content
}

export const checkCustomerPaymentMethod = async (customerId: string): Promise<boolean> => {
    const response = await axiosInstance.get(`/stripe/customer/check-payment-method/${customerId}`)

    return response.data.content
}

export const getSubscriptionTypes = async (
    interval: string,
    isFreeTrial: boolean = false,
): Promise<SubscriptionType[]> => {
    const response = await axiosInstance.get(
        `/public/subscription-types?interval=${interval} ${isFreeTrial ? '&isFreeTrial=true' : ''}`,
    )

    return response.data.content
}

export const createSubscription = async (
    data: UpdateSubscriptionType,
): Promise<CreateSubscriptionRes> => {
    const response = await axiosInstance.post('/profiles/business/update-subscription', {
        planId: data.planId,
        profileId: data.userId,
    })

    return response.data.content
}

export const updateSubscription = async (data: UpdateSubscriptionType) => {
    const response = await axiosInstance.post('/stripe/change/plan', data)

    return response
}

export const getNextAutoBillDate = async (businessId: string): Promise<string> => {
    const response = await axiosInstance.get(
        `stripe/subscription/next-auto-bill-date/${businessId}`,
    )

    return formatTo24HourClock(response.data.content, COMPLETE_FORMAT_WITHOUT_TIME)
}

export const getSubscriptionMatrix = async (interval: string): Promise<SubscriptionMatrix> => {
    const response = await axiosInstance.get(`/public/subscription-matrix/${interval}`)

    return response.data.content
}

export const checkSubscriptionFeatureRestriction = async (
    businessId: string,
    name: string,
): Promise<boolean> => {
    const response = await axiosInstance.get(
        `/subscription/check-restriction?businessId=${businessId}&name=${name}`,
    )

    return response.data.content as boolean
}

export const getSubscriptionNames = async (): Promise<string[]> => {
    const response = await axiosInstance.get(`/subscription/names`)

    return response.data.content
}

export const hasActiveSubscription = async (customerId: string | undefined): Promise<boolean> => {
    if (!customerId) return false

    const response = await axiosInstance.get(`stripe/customer/active-subscription/${customerId}`)

    return !!response.data.content
}

export const getSubSeats = async (businessId: string): Promise<SubSeatType> => {
    const response = await axiosInstance.get(`/subscription/seat-count?businessId=${businessId}`)

    return response.data.content
}

export const checkSubsStatus = async (profileId: string) => {
    const response = await axiosInstance.get(`/stripe/check-active-subs/${profileId}`)

    return response.data.content
}
