import { useAtomValue } from 'jotai'
// import JobList from './JobList'
// import JobStatuses from './JobStatuses'
// import JobFilterDrawer from './JobDateFilter'
// import StatusFilter from './StatusFilter'
import { timezoneAtom, userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import Spinner from '@/components/Spinner'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { getEmployeeById } from '@/api/profile'
import { FullProfileType } from '@/api/profile/schema'
import { getRouteByCrewMemberId } from '@/api/routing'
import { useNavigate } from 'react-router-dom'
import { IoLocationSharp } from 'react-icons/io5'
import { FaCalendarAlt, FaClock } from 'react-icons/fa'
import i18n from '@/i18n'
import { COMPLETE_FORMAT_WITHOUT_TIME, SPANISH_FORMAT } from '@/constants'
import { formatDateTime } from '../Jobs/JobGallery/JobDateFilter/DateSelector'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { cn } from '@/utils/helper'
import { BsFillPeopleFill } from 'react-icons/bs'
import { useEffect, useState } from 'react'

const RouteList = () => {
    const user = useAtomValue(userAtom) as FullProfileType
    const navigate = useNavigate()
    const { profileId = ''} = user
    // const { startDate = '', endDate = '' } = selectedDateFilter
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const [loading, setLoading] = useState(true)
    


    const { data: employee, isLoading: isEmployeeLoading } = useQuery({
        enabled: !!profileId,
        queryKey: ['employee', profileId],
        queryFn: () => getEmployeeById(profileId),
    })

    const { data: routeList, isLoading: isRouteListLoading } = useQuery({
        
        
        queryKey: ['routeList', user.crewMemberId],
        refetchInterval: 600000,
        queryFn: () =>
            getRouteByCrewMemberId(
                user.crewMemberId as string,
                user.profileId as string,
            ),
        enabled: !!user.crewMemberId,
        retry: 2,
        retryDelay: 5000
    })

    console.log("🚀 ~ RouteList ~ isRouteListLoading:", isRouteListLoading) 

    useEffect(() => {
        setLoading(isRouteListLoading)
    },[isRouteListLoading])

    // const jobList = routeList === undefined ? [] : routeList?.content

    if (isEmployeeLoading || loading)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    if (!employee?.crewMemberId)
        return (
            <>
                <div className='mx-4 my-16'>
                    <div className='bg-white rounded-md flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-24 h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white mt-6'>
                        You're not a part of any crew yet
                    </p>
                </div>
                {/* <JobFilterDrawer /> */}
            </>
        )

    return (
        <>
            <div className='flex flex-row w-full bg-zentive-green-light py-3 justify-center'>
                <div className="text-center">
                    <p> My Routes </p>
                </div>
            </div>
        <div className='flex flex-col space-y-3 mt-6 mb-32 px-5'>
            {(routeList?.length !== 0 && routeList !== undefined) ? (
                routeList?.map((route: any, index: any) => (
                    <div
                        key={index}
                        role='button'
                        onClick={() => navigate(`/crew/route/${route.routeId}`)}
                        className='w-10/12 mx-auto bg-white border border-zentive-green-dark py-2 rounded-md p-2'
                    >
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row gap-x-2 mr-1 3xs:text-xs'>
                                <IoLocationSharp className='text-zentive-blue-dark h-5 w-5' />
                                <p>Route Number:    {route.routeNumber}</p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-2 text-sm gap-y-1'>
                            <div className='flex flex-row gap-x-2'>
                                    <FaCalendarAlt className='text-zentive-blue-dark h-3 w-3 mt-1' />
                                    <p className='ml-1 3xs:text-xs'>
                                        <span>
                                            {formatDateTime(
                                                route?.jobDueDate,
                                                timezone?.timeZoneId as string,
                                                i18n.language === 'es'
                                                    ? SPANISH_FORMAT
                                                    : COMPLETE_FORMAT_WITHOUT_TIME,
                                            )}
                                        </span>
                                    </p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-2 text-sm gap-y-1'>
                            <div className='flex flex-row gap-x-6'>
                                <BsFillPeopleFill className='text-zentive-blue-dark h-3 w-3 mt-1' />
                                <div className='flex flex-row'>
                                    {route?.jobGroup &&
                                        route?.jobGroup
                                            .slice(0, 5)
                                            ?.map((customer: any, index: any) => (
                                                <div
                                                    key={customer.job?.quote.profileId}
                                                    className={cn(
                                                        'relative h-[23px] w-[23px]',
                                                        index > 0 ? '-ml-6' : '-ml-4',
                                                    )}
                                                >
                                                    {customer.job?.quote.profile
                                                        ?.profileImage ? (
                                                        <img
                                                            src={
                                                                typeof customer.job?.quote
                                                                    .profile
                                                                    ?.profileImage ===
                                                                'string'
                                                                    ? customer.job?.quote
                                                                            .profile
                                                                            ?.profileImage
                                                                    : undefined
                                                            }
                                                            alt={`Profile ${index + 1}`}
                                                            className='h-[23px] w-[23px] rounded-full mr-1 absolute border-white border-2'
                                                            style={{
                                                                left: `${index * 17}px`,
                                                                zIndex:
                                                                    route?.jobGroup
                                                                        ?.length! - index,
                                                            }}
                                                            title={`${customer.job?.quote.profile?.firstName} ${customer.job?.quote.profile?.lastName}`}
                                                        />
                                                    ) : (
                                                        <div
                                                            className='h-[23px] w-[23px] rounded-full border-white border-2 bg-zentive-blue-dark flex justify-center items-center mr-1 absolute'
                                                            style={{
                                                                left: `${index * 18}px`,
                                                                zIndex:
                                                                    route?.jobGroup
                                                                        ?.length! - index,
                                                            }}
                                                            title={`${customer.job?.quote.profile?.firstName} ${customer.job?.quote.profile?.lastName}`}
                                                        >
                                                            <span className='text-zentive-gray-light text-sm'>
                                                                {`${
                                                                    customer.job?.quote.profile?.firstName
                                                                        ?.charAt(0)
                                                                        ?.toUpperCase() ??
                                                                    ''
                                                                }${
                                                                    customer.job?.quote.profile?.lastName
                                                                        ?.charAt(0)
                                                                        ?.toUpperCase() ??
                                                                    ''
                                                                }`}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                    {route?.jobGroup && route?.jobGroup?.length > 5 && (
                                        <div className='flex justify-end w-[5.5rem]'>
                                            ...
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col mt-2 text-sm gap-y-1'>
                            <div className='flex flex-row gap-x-2'>
                                    <FaClock className='text-zentive-blue-dark h-3 w-3 mt-1' />
                                    <p className='ml-1 3xs:text-xs'>
                                        {route.timeZone}
                                    </p>
                            </div>
                        </div>
                    </div>
                    
                ))

            ) : (
                <div>
                    <div className='mx-4 mt-4'>
                            <div className='flex justify-center bg-white rounded-md'>
                                <img
                                    src={emptyPlaceholder}
                                    alt='empty-table-placeholder'
                                    className='my-8 h-36'
                                />
                            </div>
                            <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                                No Routes Listed
                            </p>
                            <p className='pb-4 text-center text-white'>There are no routes listed as of this moment</p>
                        </div>
                </div>

            )}
        </div>
        </>

    )

}

export default RouteList
