import { DiscountListType } from '@/api/discounts/schema'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { DISCOUNT_TYPE, ROLE, US_FORMAT } from '@/constants'
import { timezoneAtom } from '@/store/auth'
import { displayCurrency } from '@/utils/helper'
import { formatToUTCWithOffset } from '@/utils/time'
import { TrashIcon } from '@radix-ui/react-icons'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import DeleteDiscountModal from './DeleteModal'
import { Button } from '@/components/Button'
import useViewedAsUser from '@/hooks/useViewedAsUser'

interface DiscountTableProps {
    discountList: DiscountListType
}

export const DiscountTable: React.FC<DiscountTableProps> = ({ discountList }) => {
    const tz = useAtomValue(timezoneAtom)
    const [open, setOpen] = useState<boolean>(false)
    const [coupon, setCoupon] = useState<string>('')

    const user = useViewedAsUser()

    const isAdmin = user?.role?.roleName === ROLE.ADMIN

    const handleDelete = (c: string) => {
        setCoupon(c)
        setOpen(true)
    }

    return (
        <div className='bg-white'>
            <Table>
                <TableHeader>
                    <TableRow className='whitespace-nowrap'>
                        <TableHead className='flex flex-row gap-2 items-center font-semibold text-zentive-gray-medium text-base px-10'>
                            Discount Code
                        </TableHead>
                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                            Discount
                        </TableHead>
                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                            Discount Name
                        </TableHead>
                        {isAdmin && (
                            <>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Duration
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Eligibility
                                </TableHead>
                                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                    Usage Limit
                                </TableHead>
                            </>
                        )}

                        <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                            Action
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {discountList?.content &&
                        discountList?.content?.map((data, index) => (
                            <TableRow
                                key={data?.discountCode + '-' + index}
                                className='whitespace-nowrap'
                            >
                                <TableCell className='flex-row items-center text-start text-zentive-black text-base overflow-hidden text-ellipsis pl-10 gap-2'>
                                    {data.discountCode}
                                </TableCell>
                                <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                    {data.discountType === DISCOUNT_TYPE.percentage
                                        ? `${data.amount}%`
                                        : `${displayCurrency(data.amount)}`}
                                </TableCell>
                                <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                    {data.name}
                                </TableCell>
                                {isAdmin && (
                                    <>
                                        <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis...'>
                                            {`${formatToUTCWithOffset(
                                                data.startDate,
                                                tz?.timeZoneId as string,
                                                US_FORMAT,
                                            )} - ${formatToUTCWithOffset(
                                                data.endDate,
                                                tz?.timeZoneId as string,
                                                US_FORMAT,
                                            )}`}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                            {data?.eligibility?.join(', ')}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-end text-zentive-black text-base overflow-hidden text-ellipsis'>
                                            {data?.usageLimit}
                                        </TableCell>
                                    </>
                                )}
                                <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                    <Button
                                        variant={'ghost'}
                                        onClick={() => handleDelete(data.discountCode)}
                                    >
                                        <TrashIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <DeleteDiscountModal open={open} setOpen={setOpen} coupon={coupon} />
        </div>
    )
}
