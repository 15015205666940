import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
} from '@/components/AlertDialog'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Dispatch, SetStateAction } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { deleteDiscount } from '@/api/discounts'
import { useToast } from '@/hooks/useToast'
import { Button } from '@/components/Button'

interface RestoreModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    coupon: string
}

const DeleteDiscountModal: React.FC<RestoreModalProps> = ({ setOpen, open, coupon }) => {
    const { toast } = useToast()

    const queryClient = useQueryClient()

    const { mutate: deleteCouponMu, isPending: loading } = useMutation<string, AxiosError, string>({
        mutationFn: deleteDiscount,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['discounts'] })

            toast({
                description: 'Coupon code deleted successfully',
                duration: 2000,
            })
            setOpen(false)
        },
    })

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            Delete Coupon Code {coupon}
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            Are you sure you want to delete this coupon code? This action is
                            irreversible.
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-[15px] pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <Button
                            variant={'outline'}
                            onClick={() => setOpen(false)}
                            disabled={loading}
                            className='w-97'
                        >
                            Cancel
                        </Button>
                        <AlertDialogAction
                            onClick={() => {
                                deleteCouponMu(coupon)
                            }}
                            disabled={loading}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            Submit
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteDiscountModal
