import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { EmployeeStatusModalProps } from '../../EmployeeSettings'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { FaRegTrashCan } from 'react-icons/fa6'
import { Button } from '@/components/Button'
import { useQueryClient } from '@tanstack/react-query'
import { FullProfileType } from '@/api/profile/schema'
import { viewedAsUserAtom, userAtom, isViewedAsAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { SubSeatType } from '@/api/subscription/schema'
import { useState } from 'react'
import RestoreProfileJobRestrictionModal from '@/components/RestoreProfileJobRestrictionModal/indexs'

const RestoreEmployeeModal = ({
    handleProfileStatusUpdate,
    onOpenChange,
    open,
}: EmployeeStatusModalProps) => {
    const handleStatusUpdate = async () => {
        await handleProfileStatusUpdate()
    }

    const [openRestoreModal, setOpenRestoreModal] = useState(false)
    
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const queryClient = useQueryClient()

    const subSeat = queryClient.getQueryData<SubSeatType>(['subSeat', user.businessId])
    console.log("🚀 ~ subSeat:", subSeat)

    const availSeat = (subSeat?.numberOfSeats || 0) - ((subSeat?.coOwner || 0) + (subSeat?.owner || 0) + (subSeat?.crew || 0))

    const onSubmit = async () => {
        if (availSeat <= 0) {
            setOpenRestoreModal(true)
            return
        }
        handleStatusUpdate()
    }
    return (
        <>
            <AlertDialog open={open} onOpenChange={onOpenChange}>
                <AlertDialogTrigger asChild>
                    <Button
                        variant='default'
                        onClick={() => onOpenChange(true)}
                        className='px-14 py-6 my-4 justify-center bg-zentive-green-dark border shadow-md text-white hover:none'
                    >
                        <FaRegTrashCan className='mr-2' />
                        Restore Account
                    </Button>
                </AlertDialogTrigger>
                <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                    <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                        <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                            <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                        </div>
                        <div className=' overflow-auto'>
                            <AlertDialogTitle className='text-left font-semibold text-2xl text-zentive-black mt-[10px]'>
                                Restore Account
                            </AlertDialogTitle>
                            <AlertDialogDescription className='text-left text-base text-zentive-gray-medium mt-[18px]'>
                                By restoring the account, user will regain the account status the user
                                previously have.
                            </AlertDialogDescription>
                            <AlertDialogDescription className='text-left text-base text-zentive-gray-medium mt-[18px]'>
                                Are you sure you want to restore this account?
                            </AlertDialogDescription>
                        </div>
                    </div>
                    <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                        <div className='w-full flex justify-end items-end gap-[16px]'>
                            <AlertDialogCancel className='w-[155px] h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction
                                onClick={onSubmit}
                                className='w-[155px] h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                            >
                                Restore
                            </AlertDialogAction>
                        </div>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <RestoreProfileJobRestrictionModal
                open={openRestoreModal}
                setOpen={setOpenRestoreModal}
                type='employee'
            />
        </>
    )
}

export default RestoreEmployeeModal
