import { PaginationSchema } from '@/components/Pagination/schema'
import { stringValidator } from '@/utils/regex'
import { z } from 'zod'

export const taxSchema = z.object({
    taxId: z.string().optional(),
    businessId: z.string().max(255),
    prodStripeTaxId: z.string(),
    expenseStripeTaxId: z.string(),
    serviceStripeTaxId: z.string(),
    description: z.string().max(255).optional(),
    state: z.string().optional(),
    name: z.string(),
    prodPct: z.number().default(0),
    expensePct: z.number().default(0),
    servicePct: z.number().default(0),
    taxType: z.string(),
    status: z.string(),
})
export const createTaxRateSchema = z.object({
    description: z.string().optional(),
    businessId: z.string(),
    state: z.string().optional(),
    name: stringValidator('Tax Name', { isRequired: true }),
    prodPct: z.number(),
    expensePct: z.number(),
    servicePct: z.number(),
    taxType: z.string(),
})

export const getTaxRatesSchema = z.object({
    content: z.array(taxSchema),
    meta: PaginationSchema,
})

export const updateTaxRateStatusSchema = z.object({
    taxRateId: z.string(),
    status: z.string(),
})

export type CreateTaxRateType = z.infer<typeof createTaxRateSchema>
export type TaxType = z.infer<typeof taxSchema>
export type UpdateTaxRateStatusType = z.infer<typeof updateTaxRateStatusSchema>
export type TaxRateListType = z.infer<typeof getTaxRatesSchema>
