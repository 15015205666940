import React, { FormEventHandler, useEffect, useState } from 'react'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Checkbox } from '@/components/Checkbox'
import { createStripeSubscription } from '@/api/payment'
import goTrueClient from '@/api/goTrueClient'
import { useLocation, useNavigate } from 'react-router-dom'
import { sendWelcomeEmail } from '@/api/profile'
import { Button } from '@/components/Button'
import { useSetAtom } from 'jotai'
import { paymentStepAtom } from '@/store/payment'
import { PAYMENT_BTN_STYLE, PAYMENT_FIELD_STYLE } from '@/constants'
import { RESET } from 'jotai/utils'
import SkeletonActionButtons from '../../../PaymentProcess/PaymentInformation/SkeletonActionButtons'
import StripeHeader from '../../../PaymentProcess/PaymentInformation/StripeHeader'
import { toast } from '@/hooks/useToast'

const PaymentInformation: React.FC = () => {
    const stripe = useStripe()
    const elements = useElements()
    const [agree, setAgree] = useState<boolean>(false)

    const [cardNumberError, setCardNumberError] = useState<string | null>(null)
    const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false)

    const [cardExpiryError, setCardExpiryError] = useState<string | null>(null)
    const [cardExpiryComplete, setCardExpiryComplete] = useState<boolean>(false)

    const [cardCvcError, setCardCvcError] = useState<string | null>(null)
    const [cardCvcComplete, setCardCvcComplete] = useState<boolean>(false)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const planId = searchParams.get('planid');

    const setCurrentStep = useSetAtom(paymentStepAtom)

    const navigate = useNavigate()

    const { data: authSession } = useQuery({
        enabled: true,
        queryKey: ['session'],
        queryFn: () => goTrueClient.getSession(),
    })

    const { data: clientSecret } = useQuery<unknown, AxiosError, string>({
        enabled: authSession?.data.session?.user.id !== undefined,
        queryKey: ['userId', authSession?.data.session?.user.id],
        queryFn: () => createStripeSubscription(authSession?.data.session?.user.id ?? '', false, planId ?? ''),
        retry: 0,
    })

    const welcomeMu = useMutation({
        mutationFn: () => sendWelcomeEmail(authSession?.data.session?.user.email ?? ''),
        onSuccess: () => {
            toast({
                description: 'Successfully Renewed Subscription',
                duration: 2000,
            })
            navigate('/')
        },
        onError: (err) => {
            if (err instanceof AxiosError) {
                console.error(err.message)
            }
        },
    })

    const paymentMu = useMutation<unknown, StripeError>({
        mutationFn: async () => {
            if (!stripe || !elements) return

            const cardElement = elements.getElement(CardNumberElement)

            if (!cardElement) {
                console.error('Card Element not found')
                throw new Error('Card Element not found')
            }

            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret ?? '', {
                payment_method: {
                    card: cardElement,
                },
            })

            if (error) {
                if (error.decline_code != null) {
                    switch (error.decline_code) {
                        case 'generic_decline':
                            setCardNumberError('Payment declined. Try again later.')
                            break
                        case 'insufficient_funds':
                            setCardNumberError('Insufficient funds. Retry payment.')
                            break
                        case 'lost_card':
                            setCardNumberError('Card reported lost. Use another.')
                            break
                        case 'stolen_card':
                            setCardNumberError('Payment declined. Try again later.')
                            break
                        case 'card_velocity_exceeded':
                            setCardNumberError('Transaction limit exceeded. Wait and retry.')
                            break
                        default:
                            setCardNumberError('Payment declined. Try again later.')
                            break
                    }
                } else {
                    switch (error.code) {
                        case 'expired_card':
                            setCardExpiryError(
                                'Your credit card has expired. Please use another credit card information and try again.',
                            )
                            break
                        case 'incorrect_cvc':
                            setCardCvcError('Incorrect CVC. Retry with correct code.')
                            break
                        case 'processing_error':
                            setCardNumberError('Payment processing error. Retry.')
                            break
                        case 'incorrect_number':
                            setCardNumberError('Incorrect card number. Retry with correct number.')
                            break
                        default:
                            setCardNumberError('Payment declined. Try again later.')
                            break
                    }
                }
                throw new Error(`Error confirming card payment: ${error.message}`)
            }

            return paymentIntent
        },
        onSuccess: () => {
            welcomeMu.mutate()
        },
        onError: (error) => {
            // Handle errors if needed
            console.error('Payment error:', error)
            if (error.payment_intent?.status === 'requires_action') {
                // Handle additional authentication
            }
        },
    })

    const handlePayment: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        if (!agree) return

        try {
            await paymentMu.mutateAsync()
        } catch (error) {
            // Handle mutation error
            console.error('Mutation error:', error)
        }
    }

    const isNextButtonDisabled =
        agree &&
        cardNumberComplete &&
        cardExpiryComplete &&
        cardCvcComplete &&
        cardNumberError === null &&
        cardExpiryError === null &&
        cardCvcError === null &&
        !paymentMu.isPending

    useEffect(() => {
        const handleCardNumberChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardNumberComplete(complete)
            setCardNumberError(error ? error.message ?? '' : null)
        }

        const handleCardExpiryChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardExpiryComplete(complete)
            setCardExpiryError(error ? error.message ?? '' : null)
        }

        const handleCardCvcChange = ({
            complete,
            error,
        }: {
            complete: boolean
            error?: StripeError
        }) => {
            setCardCvcComplete(complete)
            setCardCvcError(error ? error.message ?? '' : null)
        }

        const cardNumberElement = elements
            ?.getElement(CardNumberElement)
            ?.on('change', handleCardNumberChange)
        const cardExpiryElement = elements
            ?.getElement(CardExpiryElement)
            ?.on('change', handleCardExpiryChange)
        const cardCvcElement = elements
            ?.getElement(CardCvcElement)
            ?.on('change', handleCardCvcChange)

        return () => {
            if (cardNumberElement) cardNumberElement.off('change', handleCardNumberChange)
            if (cardExpiryElement) cardExpiryElement.off('change', handleCardExpiryChange)
            if (cardCvcElement) cardCvcElement.off('change', handleCardCvcChange)
        }
    }, [elements])

    return (
        <>
            <StripeHeader />
            <form className='h-fit w-full flex flex-col items-center' onSubmit={handlePayment}>
                <div className='grid grid-cols-2 gap-4 w-[300px] absolute'>
                    <div className='col-span-2 w-[300px] -mt-1'>
                        <CardNumberElement
                            options={{
                                showIcon: false,
                                style: {
                                    base: { color: cardNumberError ? '#F44336' : '' },
                                }, //changing the font color in the className does not work so its done here
                            }}
                            className={`${PAYMENT_FIELD_STYLE} `}
                        />
                        {cardNumberError ? (
                            <div className='text-red-500 mt-1 text-sm static'>
                                {cardNumberError}
                            </div>
                        ) : null}
                    </div>
                    <div className='col-span-1 -mt-1.5'>
                        <CardExpiryElement
                            options={{
                                style: {
                                    base: { color: cardExpiryError ? '#F44336' : '' },
                                }, //changing the font color in the className does not work so its done here
                            }}
                            className={`${PAYMENT_FIELD_STYLE} `}
                        />
                        {cardExpiryError ? (
                            <div className='text-red-500 mt-1 w-[150px] text-sm'>
                                {cardExpiryError}
                            </div>
                        ) : null}
                    </div>
                    <div className='col-span-1 -mt-1.5'>
                        <CardCvcElement
                            options={{
                                style: { base: { color: cardCvcError ? '#F44336' : '' } }, //changing the font color in the className does not work so its done here
                            }}
                            className={`${PAYMENT_FIELD_STYLE}`}
                        />
                        {cardCvcError ? (
                            <div className='text-red-500 mt-1 w-[150px] text-sm'>
                                {cardCvcError}
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className='mt-[165px] static'>
                    <p className='text-center'>
                        Your personal data will only be used to process your order, support your
                        experience throughout this application, and for other purposes described in
                        our &nbsp;
                        <a
                            href='https://zentive.io/privacy-policy/'
                            target='_blank'
                            className='text-indigo-900 underline'
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
                <div className='flex text-left justify-center items-center my-7 '>
                    <span className='flex self-start p-1'>
                        <Checkbox checked={agree} onCheckedChange={() => setAgree(!agree)} />
                    </span>

                    <p>
                        I have read and agree to the website &nbsp;
                        <a
                            href='https://zentive.io/terms-of-service/'
                            target='_blank'
                            className='text-indigo-900 underline'
                        >
                            Terms and Conditions
                        </a>
                        <span className='text-red-800'>*</span>
                    </p>
                </div>

                <div className='absolute bottom-0 left-0 h-[75px] w-full px-7 bg-zentive-gray-light'>
                    {clientSecret ? (
                        <div className='flex items-center justify-between gap-[16px] w-full h-full'>
                            <Button
                                className='w-full h-[43px] rounded-[4px] text-base font-semibold'
                                disabled={paymentMu.isPending}
                                onClick={() => {
                                    navigate('/renewal/matrix')
                                    setCurrentStep(RESET)
                                }}
                                type='button'
                                variant='outline'
                            >
                                Back
                            </Button>

                            <Button
                                className={PAYMENT_BTN_STYLE}
                                disabled={!isNextButtonDisabled}
                                type='submit'
                            >
                                Next
                            </Button>
                        </div>
                    ) : (
                        <SkeletonActionButtons />
                    )}
                </div>
            </form>
        </>
    )
}

export default PaymentInformation
