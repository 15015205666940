import { axiosInstance } from '../axiosInstance'

export const createStripeSubscription = async (
    userId: string,
    changePlan: boolean,
    planId?: string,
): Promise<string> => {
    const res = await axiosInstance.post(
        `stripe/subscription?profileID=${userId}&changePlan=${changePlan}&planID=${planId}`,
    )

    return res.data.content
}
