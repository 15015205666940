import { CardContent, CardTitle } from '@/components/Card'
import ContentService from './Service'
import Expenses from './Expenses'
import BillingAmount from './BillingAmount'
import { startTransition, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useAtom, useAtomValue } from 'jotai'
import { customerInvoiceAtom, invoiceContentAtom } from '@/store/owner'
import { Textarea } from '@/components/TextArea'
import { InvoiceType } from '@/api/invoicing/schema'
import { jobGenerateInvoiceAtom, selectedJobForInvoiceAtom } from '@/store/job'
import { DEFAULT_ADDRESS } from '@/constants'

const AddInvoiceContent = () => {
    const [internalNotes, setInternalNotes] = useState('')
    const [invoiceContent, setInvoiceContent] = useAtom(invoiceContentAtom)
    const jobForInvoicing = useAtomValue(selectedJobForInvoiceAtom)

    const { setValue } = useFormContext<InvoiceType>()

    const customer = useAtomValue(customerInvoiceAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)

    const {
        internalNotes: invoiceContentInternalNotes,
        totalProduct,
        totalService,
    } = invoiceContent

    const handleInternalNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target
        setInternalNotes(value)
        setValue('internalNotes', value)
    }

    useEffect(() => {
        if (!generateInvoiceData) {
            if (customer && jobForInvoicing?.quote) {
                startTransition(() => {
                    setInvoiceContent((prevVal) => ({
                        ...prevVal,
                        subTotal:
                            prevVal.subTotal +
                            invoiceContent.totalExpense +
                            invoiceContent.totalProduct +
                            invoiceContent.totalService,
                    }))
                })
            } else {
                startTransition(() => {
                    setInvoiceContent((prevVal) => ({
                        ...prevVal,
                        subTotal: prevVal.subTotal,
                    }))
                })
            }

            startTransition(() => {
                setValue('laborCost', invoiceContent.laborCost)
                setValue('subTotal', invoiceContent.subTotal)
                setInternalNotes(
                    invoiceContent.internalNotes != '' ? invoiceContent.internalNotes : '',
                )
            })
        } else {
            startTransition(() => {
                setValue('expense', generateInvoiceData.expense)
                setValue('status', 'draft')
                setValue('quote', generateInvoiceData.quote)
                setValue('quoteId', generateInvoiceData.quoteId)
                setValue('profileId', generateInvoiceData?.quote?.profileId as string)
                setValue('profile', generateInvoiceData?.quote?.profile)
                setValue('profile.address', generateInvoiceData?.address ?? DEFAULT_ADDRESS)
                setValue('quote.profile.address', generateInvoiceData?.address ?? DEFAULT_ADDRESS)
                setValue('businessId', generateInvoiceData?.quote?.businessId as string)
            })
        }
    }, [
        invoiceContentInternalNotes,
        totalProduct,
        totalService,
        generateInvoiceData?.expense,
        generateInvoiceData?.address,
    ])

    return (
        <CardContent className='pt-9 pb-20 mx-5'>
            <ContentService />
            <Expenses />
            <BillingAmount />
            <div className='border border-[#EBEBEB] p-6 '>
                <CardTitle className='text-lg mb-6'>Internal notes</CardTitle>
                <Textarea
                    className='flex w-full pt-3 text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm'
                    placeholder='Description'
                    value={internalNotes}
                    onChange={handleInternalNotesChange}
                />
                {/* TODO: Upload image */}
            </div>
        </CardContent>
    )
}

export default AddInvoiceContent
