import { QuoteType } from '@/api/quoting/schema'
import { Button } from '@/components/Button'
import {
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSetAtom } from 'jotai'
import { quoteTaxAtom } from '@/store/owner'
import { DEFAULT_QUOTE_TAX_VALUE } from '@/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getTaxRateById, getTaxRates } from '@/api/tax'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { TaxType } from '@/api/tax/schema'
import { AxiosError } from 'axios'

export interface TaxesParams {
    name: string
    productTax: number
    isProductsTaxable: boolean
    serviceTax: number
    isServicesTaxable: boolean
    expenseTax: number
    isExpensesTaxable: boolean
    taxId: string
}

const AddTaxDialog = () => {
    const [taxesToSave, setTaxesToSave] = useState<TaxesParams>(DEFAULT_QUOTE_TAX_VALUE)
    const setTaxes = useSetAtom(quoteTaxAtom)
    const [selectedTaxRateId, setSelectedTaxRateId] = useState<string | null>(null)

    const user = useViewedAsUser()

    const { setValue } = useFormContext<QuoteType>()

    const { data: taxRates } = useQuery({
        queryKey: ['quoteTaxRates', selectedTaxRateId],
        queryFn: () => getTaxRates(user?.businessId, '', { page: 1, pageSize: 1000 }),
    })

    const { mutate: taxRateMu } = useMutation<TaxType, AxiosError, string>({
        mutationFn: getTaxRateById,
        onSuccess: (data) => {
            setTaxesToSave({
                serviceTax: data?.servicePct ?? 0,
                expenseTax: data?.expensePct ?? 0,
                productTax: data?.prodPct ?? 0,
                name: data?.name ?? '',
                isProductsTaxable: (data?.prodPct ?? 0) > 0,
                isServicesTaxable: (data?.servicePct ?? 0) > 0,
                isExpensesTaxable: (data?.expensePct ?? 0) > 0,
                taxId: data.taxId ?? '',
            })
        },
    })

    const handleSaveTaxes = () => {
        setTaxes({
            name: taxesToSave?.name ?? '',
            serviceTax: taxesToSave?.serviceTax ?? 0,
            isServicesTaxable: taxesToSave.isServicesTaxable,
            productTax: taxesToSave?.productTax ?? 0,
            isProductsTaxable: taxesToSave?.isProductsTaxable,
            expenseTax: taxesToSave?.expenseTax ?? 0,
            isExpensesTaxable: taxesToSave?.isExpensesTaxable,
            taxId: taxesToSave?.taxId,
        })

        setValue('serviceTax', taxesToSave?.serviceTax ?? 0)
        setValue('productTax', taxesToSave?.productTax ?? 0)
        setValue('expenseTax', taxesToSave?.expenseTax ?? 0)
        setValue('taxId', taxesToSave?.taxId)
    }

    const handleTaxCancel = () => {
        setTaxesToSave({
            name: '',
            productTax: 0,
            isProductsTaxable: false,
            serviceTax: 0,
            isServicesTaxable: false,
            expenseTax: 0,
            isExpensesTaxable: false,
            taxId: '',
        })
        setSelectedTaxRateId('')
    }

    return (
        <DialogPortal>
            <DialogOverlay>
                <DialogContent className='max-w-[400px] w-[400px] max-h-[470px] h-[470px] rounded-md'>
                    <DialogHeader className='m-6'>
                        <DialogTitle className='mb-2'>
                            <div className='text-2xl font-semibold text-left mb-6'>
                                Add Tax Rate
                            </div>
                        </DialogTitle>
                        <DialogDescription className='no-scrollbar'>
                            <select
                                className='w-full flex mb-8 justify-center items-center text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                onChange={(e) => taxRateMu(e.target.value)}
                            >
                                <option value=''>Select Tax Rate</option>
                                {taxRates?.content?.map((t) => (
                                    <option
                                        key={t.taxId}
                                        value={t.taxId}
                                        selected={taxesToSave.name === t.name}
                                    >
                                        {t.name}
                                    </option>
                                ))}
                            </select>

                            <Input
                                className='w-full mb-8 -mt-3 flex justify-center items-center text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Products Tax Rate %'
                                value={taxesToSave.productTax ?? 0}
                                name='rate'
                                type='number'
                                disabled
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />

                            <Input
                                className='w-full mb-8 -mt-3 flex justify-center items-center text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Services Tax Rate %'
                                value={taxesToSave.serviceTax ?? 0}
                                name='rate'
                                type='number'
                                disabled
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />

                            <Input
                                className='w-full mb-8 -mt-3 flex justify-center items-center text-base h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                placeholder='Expenses Tax Rate %'
                                value={taxesToSave.expenseTax ?? 0}
                                name='rate'
                                type='number'
                                disabled
                                onKeyDown={(e) => {
                                    if (
                                        e.key === '-' ||
                                        e.key === '+' ||
                                        e.key === 'e' ||
                                        e.key === 'E'
                                    ) {
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className='absolute self-end py-4 px-6 w-full items-center justify-center bg-zentive-gray-light'>
                        <div className='flex flex-row gap-x-4 justify-center w-full'>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-[50%]'
                                    onClick={handleTaxCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogClose>

                            <DialogClose asChild>
                                <Button
                                    type='submit'
                                    className='w-[50%]'
                                    disabled={
                                        (taxesToSave.name !== '' &&
                                            taxesToSave.serviceTax > 0 &&
                                            taxesToSave.isServicesTaxable) ||
                                        (taxesToSave.productTax > 0 &&
                                            taxesToSave.isProductsTaxable) ||
                                        (taxesToSave.expenseTax > 0 &&
                                            taxesToSave.isExpensesTaxable)
                                            ? false
                                            : true
                                    }
                                    onClick={handleSaveTaxes}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    )
}

export default AddTaxDialog
