import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import { getQuote } from '@/api/quoting'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn, displayCurrency } from '@/utils/helper'
import { computeQuote } from '@/utils/calc'
import RespondAlert from './RespondAlert'
import { QUOTE_STATUS } from '@/constants'

const tableHeader = ['Product/Service', 'Description', 'Quantity', 'Total']

const RespondQuote = () => {
    const planParam = new URLSearchParams(window.location.search)
    const quoteId = planParam.get('quoteId')

    const {
        data: quote,
        isLoading,
        error,
    } = useQuery<unknown, AxiosError, QuoteType>({
        queryKey: ['quote', quoteId],
        queryFn: () => getQuote(quoteId as string),
        enabled: !!quoteId,
    })

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error loading quote</div>
    }

    if (!quote) {
        return null
    }

    const computedQuote = computeQuote(quote, false)

    const {
        discount,
        laborCost,
        total,
        taxName,
        productsTaxPercentage,
        servicesTaxPercentage,
        expensesTaxPercentage,
        productsTaxAmount,
        servicesTaxAmount,
        expensesTaxAmount,
        totalTaxPercentage,
        totalTaxAmount,
    } = computedQuote

    return (
        <>
            <div className='mt-[45px] ml-[75px] text-2xl text-zentive-gray-medium font-semibold'>
                Quotes
            </div>
            <div className='bg-zentive-green-light p-8 m-[75px] text-base'>
                <h1 className='mb-2 text-zentive-gray-medium font-semibold'> Note </h1>
                <div>
                    {' '}
                    If you have any questions about this quotation or need to request a service,
                    please log in to your account portal and go to the{' '}
                    <strong> "Service Request" </strong> section.
                </div>
            </div>
            {quote.status === QUOTE_STATUS.PENDING && (
                <Card className=' mx-[75px] mb-[75px] overflow-y-auto'>
                    <CardContent className='p-4 overflow-x-scroll md:overflow-x-auto'>
                        <CardHeader className='text-center'>
                            Would you like to proceed with this quote?
                        </CardHeader>
                        <CardFooter>
                            <div className='w-full  justify-items-center'>
                                <div className='flex flex-row space-x-4 mt-6 w-[50%] mx-auto '>
                                    <RespondAlert
                                        status={QUOTE_STATUS.DECLINED}
                                        quoteId={quote?.quoteId}
                                    />
                                    <RespondAlert
                                        status={QUOTE_STATUS.APPROVED}
                                        quoteId={quote?.quoteId}
                                    />
                                </div>
                            </div>
                        </CardFooter>
                    </CardContent>
                </Card>
            )}
            <Card className=' m-[75px] overflow-y-auto'>
                <CardContent className='p-4 overflow-x-scroll md:overflow-x-auto'>
                    <CardHeader className='flex flex-row justify-between'>
                        <div className='flex flex-col text-left '>
                            <span className='text-zentive-gray-medium font-semibold mb-2'>
                                Recipient
                            </span>
                            <span className=''>{quote?.name}</span>
                        </div>
                        <div className='flex flex-col text-left '>
                            <span className='text-zentive-gray-medium font-semibold mb-2'>
                                Property Address
                            </span>
                            <span className=''>
                                {quote?.address?.streetAddress} {quote?.address?.city},{' '}
                                {quote?.address?.state} {quote?.address?.zipCode}
                            </span>
                        </div>
                        <div className='flex flex-col text-right'>
                            <span className='text-zentive-gray-medium font-semibold mb-2'>
                                Quote Number
                            </span>
                            <span className=''>{quote?.quoteNumber}</span>
                        </div>
                    </CardHeader>

                    <div className='flex flex-col'>
                        <Table className=' border-separate  border-slate-500 px-4'>
                            <TableHeader>
                                <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                    {tableHeader?.map((header) => (
                                        <TableHead
                                            key={`${header}-head`}
                                            className={cn(
                                                'flex-row items-left text-start font-semibold text-base border bg-zentive-green-dark text-white',
                                            )}
                                        >
                                            <div className='inline-flex gap-[10px]'>{header}</div>
                                        </TableHead>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {quote?.productServiceGroup?.map((body) => (
                                    <TableRow
                                        key={body.productServiceId}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base border bg-[#C3CEC86B] '>
                                            {body.name}
                                        </TableCell>
                                        <TableCell className='flex-row  items-start text-start  text-[#191A0A] text-base border bg-[#C3CEC86B]  '>
                                            {body.description}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-base border bg-[#C3CEC86B]  '>
                                            {body.quantity ? body.quantity : 1}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start text-[#191A0A] text-base border  bg-[#C3CEC86B] '>
                                            {displayCurrency(body.cost)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className=' border border-[#C3CEC86B] mx-4'>
                            {quote?.expense?.map((body) => (
                                <div
                                    className='flex flex-row my-4 mx-8 justify-between'
                                    key={body.expenseId}
                                >
                                    <span>{body.accountName}</span>
                                    <span>${body.total}</span>
                                </div>
                            ))}
                        </div>

                        <div className='flex flex-row mt-12 mb-10 mx-4 justify-between space-x-16'>
                            <div className='flex flex-col  w-full'>
                                <span className='font-semibold mb-6'>Notes</span>
                                <div className='h-full border border-[#EBEBEB] rounded-md p-6'>
                                    <span className=''>{quote?.internalNotes}</span>
                                </div>
                            </div>
                            <div className='w-full'>
                                <div className='h-full bg-zentive-green-light rounded-md p-6'>
                                    <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                                        <div className='font-semibold'>Labor Cost</div>
                                        <div className=''>${laborCost?.toFixed(2)}</div>
                                    </div>
                                    <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                                        <div className='font-semibold'>Subtotal</div>
                                        <div className=''>${quote?.subTotal?.toFixed(2)}</div>
                                    </div>
                                    <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                                        <div className='font-semibold'>
                                            Discount{' '}
                                            {quote?.isDiscountPercent ? (
                                                <span className='text-zentive-blue-dark'>
                                                    ({quote?.discount}%)
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className=''>
                                            <span className='text-zentive-blue-dark'>
                                                -${discount?.toFixed(2)}
                                            </span>{' '}
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between px-4 h-10 mb-4 text-zentive-gray-medium'>
                                        <div className='font-semibold'>
                                            Tax{' '}
                                            <span className='text-zentive-blue-dark'>
                                                {taxName}
                                            </span>
                                            <span className='text-zentive-blue-dark'>
                                                {`(${totalTaxPercentage.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                })}%)`}
                                            </span>
                                        </div>
                                        <div className=''>
                                            {' '}
                                            $
                                            {totalTaxAmount.toLocaleString(undefined, {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            })}
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-between h-24 text-zentive-gray-medium mb-6'>
                                        {productsTaxAmount > 0 && (
                                            <div className='flex flex-row justify-between px-4'>
                                                <p className='pl-4'>
                                                    - Products {productsTaxPercentage}%{' '}
                                                </p>
                                                <p>${productsTaxAmount.toFixed(2)}</p>
                                            </div>
                                        )}
                                        {servicesTaxAmount > 0 && (
                                            <div className='flex flex-row justify-between px-4'>
                                                <p className='pl-4'>
                                                    - Services {servicesTaxPercentage}%{' '}
                                                </p>
                                                <p>${servicesTaxAmount.toFixed(2)}</p>
                                            </div>
                                        )}
                                        {expensesTaxAmount > 0 && (
                                            <div className='flex flex-row justify-between px-4'>
                                                <p className='pl-4'>
                                                    - Expenses {expensesTaxPercentage}%{' '}
                                                </p>
                                                <p>${expensesTaxAmount.toFixed(2)}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className='flex flex-row justify-between px-4 pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                                        <div className='font-semibold'>Total</div>
                                        <div className='text-zentive-green-dark font-semibold'>
                                            ${total?.toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default RespondQuote
