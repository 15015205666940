import { createInvoiceDiscount } from '@/api/discounts'
import { CreateInvoiceDiscountType, createInvoiceDiscountSchema } from '@/api/discounts/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { DISCOUNT_TYPE } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import useViewedAsUser from '@/hooks/useViewedAsUser'

export const CreateInvoiceDiscount = () => {
    const user = useViewedAsUser()

    const navigate = useNavigate()

    const { toast } = useToast()

    const queryClient = useQueryClient()

    const methods = useForm<CreateInvoiceDiscountType>({
        mode: 'onChange',
        resolver: zodResolver(createInvoiceDiscountSchema),
        defaultValues: {
            discountType: DISCOUNT_TYPE.percentage,
            businessId: user?.businessId,
        },
    })

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, isValid },
        setError,
    } = methods

    const discountType = watch('discountType')
    const amount = watch('amount')

    const { mutate: createDiscountMu, isPending: isLoading } = useMutation<
        string,
        AxiosError,
        CreateInvoiceDiscountType
    >({
        mutationFn: createInvoiceDiscount,
        onSuccess: () => {
            toast({
                description: 'Coupon Code Created Successfully',
                duration: 2000,
            })

            queryClient.invalidateQueries({ queryKey: ['discounts'] })
            navigate('/settings/discounts')
        },
        onError: () => {
            toast({
                description: 'Coupon Code already exists!',
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: CreateInvoiceDiscountType) => {
        createDiscountMu(data)
    }

    useEffect(() => {
        if (discountType === 'percentage' && amount > 100) {
            setError('amount', { message: '100% is the maximum discount percentage.' })
        }
    }, [discountType, amount, errors])

    return (
        <>
            <BreadCrumbs titleName='Add Discount' />
            <div className='flex flex-col gap-5 mt-10 bg-white p-5 items-center justify-center'>
                <div className='flex flex-col gap-5 mx-auto max-w-[75%]'>
                    <Form {...methods}>
                        <div className='grid grid-cols-2 gap-5 w-full'>
                            <FormField
                                control={control}
                                name='discountCode'
                                render={({ field }) => (
                                    <FormItem className='col-span-1'>
                                        <FormControl>
                                            <Input
                                                className='w-full'
                                                placeholder='Discount Code*'
                                                type='text'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name='name'
                                render={({ field }) => (
                                    <FormItem className='col-span-1'>
                                        <FormControl>
                                            <Input
                                                className='w-full'
                                                placeholder='Discount Name*'
                                                type='text'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            <div className='flex flex-col'>
                                <div className='flex flex-row'>
                                    <FormField
                                        control={control}
                                        name='amount'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='w-full w-[15rem]'
                                                        placeholder={`Discount (${
                                                            discountType
                                                                ? discountType ===
                                                                  DISCOUNT_TYPE.percentage
                                                                    ? '%'
                                                                    : '$'
                                                                : ''
                                                        })*`}
                                                        type='number'
                                                        {...field}
                                                        onChange={(e: any) =>
                                                            field.onChange(Number(e.target.value))
                                                        }
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <span className='py-2 ml-2 col-span-1'>
                                        <select
                                            onChange={(e) =>
                                                setValue('discountType', e.target.value)
                                            }
                                        >
                                            <option value={DISCOUNT_TYPE.percentage}>%</option>
                                            <option value={DISCOUNT_TYPE.fixed}>$</option>
                                        </select>
                                    </span>
                                </div>
                                {errors.amount && (
                                    <p className='text-red-500'>{errors.amount.message}</p>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-end justify-end gap-5'>
                            <Button
                                variant={'outline'}
                                className='w-[8rem]'
                                onClick={() => navigate(-1)}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    isLoading || !isValid // Disable if there are any form errors
                                }
                                className='w-[8rem]'
                                onClick={handleSubmit(onSubmit)}
                                type='submit'
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
