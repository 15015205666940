import BreadCrumbs from '@/components/BreadCrumbs'
import DiscountEmptyState from '@/assets/private/discounts_empty_state.svg'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import { DiscountListType } from '@/api/discounts/schema'
import { getDiscountList } from '@/api/discounts'
import { DiscountTable } from './DiscountTable'
import SearchBar from '@/components/InputSearchBox'
import { useState, useEffect } from 'react'
import { discountPaginationAtom } from '@/store/discounts'
import { useAtom } from 'jotai'
import { Pagination } from '@/components/Pagination'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { ROLE, STRIPE_COUPON_USAGETYPE } from '@/constants'

export const Discounts = () => {
    const navigate = useNavigate()

    // State to handle search input and debounce keyword
    const [keyword, setKeyword] = useState<string>('') // immediate input state
    const [debouncedKeyword, setDebouncedKeyword] = useState<string>('') // debounced state
    const [isEmpty, setIsEmpty] = useState<boolean>(false)
    const [pagination, setPagination] = useAtom(discountPaginationAtom)

    const user = useViewedAsUser()

    const isAdmin = user?.role?.roleName === ROLE.ADMIN
    const usageType = isAdmin ? STRIPE_COUPON_USAGETYPE.Subscription : STRIPE_COUPON_USAGETYPE.Invoice

    // Debounce effect for keyword search
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedKeyword(keyword)
            setPagination((prevPagination) => ({
                ...prevPagination,
                page: 1,
            }))
        }, 500) // 500ms debounce delay

        return () => {
            clearTimeout(handler)
        }
    }, [keyword])

    const { data: discountList, isLoading: loading } = useQuery<DiscountListType>({
        queryKey: ['discounts', debouncedKeyword, pagination],
        queryFn: () => getDiscountList(debouncedKeyword, pagination, user?.businessId, usageType),
        enabled: !!debouncedKeyword || keyword === '', // only fetch if there's a keyword or it's empty
    })

    useEffect(() => {
        setIsEmpty(
            discountList?.content === null &&
                (keyword === null || keyword === undefined || keyword === ''),
        )
    }, [loading, discountList])

    return (
        <>
            <BreadCrumbs titleName='Discounts' />
            <div className='mt-5'>
                {!isEmpty && (
                    <div className='flex flex-row justify-between'>
                        <SearchBar
                            onSearchChange={(e) => setKeyword(e.target.value)} // update immediate state
                            value={keyword}
                        />
                        <Button
                            onClick={() => navigate('/settings/discounts/create-discount')}
                            className='flex flex-row gap-2'
                        >
                            <PlusIcon /> Add Item
                        </Button>
                    </div>
                )}
            </div>
            {discountList?.content != null && !loading && (
                <div className='flex flex-col gap-5 mt-5'>
                    <DiscountTable discountList={discountList} />
                </div>
            )}

            {(discountList?.meta?.totalRecords as number) > 0 && (
                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={discountList?.meta?.totalRecords ?? 1}
                            pagination={pagination}
                        />
                    )}
                </div>
            )}

            {isEmpty && !loading && (
                <div className='flex flex-col items-center justify-center bg-white p-10 gap-5 text-center mt-5'>
                    <img src={DiscountEmptyState} />

                    <p className='font-bold text-zentive-green-dark text-2xl'>
                        No Discounts Available
                    </p>
                    <p className='text-zentive-gray-dark text-base'>
                        There are currently no discounts to display. <br />
                        Click the button below to create.
                    </p>
                    <Button
                        onClick={() => navigate('/settings/discounts/create-discount')}
                        className='flex flex-row gap-2'
                    >
                        <PlusIcon /> Add Discount
                    </Button>
                </div>
            )}
        </>
    )
}
