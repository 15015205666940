import { FullProfileType } from '@/api/profile/schema'
import { getRouteWithCrewId } from '@/api/routing'
import { RouteType, OwnerForemanProfileType, RoutePolylineType } from '@/api/routing/schema'
import { userAtom } from '@/store/auth'
import { isPolylineLoadingAtom, routeAtom, routeCrewPolylineAtom } from '@/store/route'
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import WrappedCrewRoutingMap from '../RouteMap'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import JobList from '../JobList'
import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { FEATURE_RESTRICTIONS } from '@/constants'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import { toast } from '@/hooks/useToast'
import Spinner from '@/components/Spinner'

const CrewRoute = () => {
    const { t } = useTranslation(['common'])
    const [selectedTab, setSelectedTab] = useState<string>('map')
    const user = useAtomValue(userAtom) as FullProfileType
    const crewMemberId = user?.crewMemberId ?? ''
    const setRoute = useSetAtom(routeAtom)
    const setPolylineList = useSetAtom(routeCrewPolylineAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const [latitude, setLatitude] = useState<number>(0)
    console.log("🚀 ~ CrewRoute ~ latitude:", latitude)
    const [longitude, setLongitude] = useState<number>(0)
    console.log("🚀 ~ CrewRoute ~ longitude:", longitude)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const { routeId } = useParams();
    const navigate = useNavigate()
    const queryClient = useQueryClient();

    const { data, isLoading, error } = useQuery<unknown, AxiosError, RouteType>({
        
        queryKey: ['route', user.crewMemberId],
        queryFn: () =>
            getRouteWithCrewId(
                routeId ? routeId : "",
                user.crewMemberId as string,
                user.profileId as string,
            ),
        enabled: !!user.crewMemberId,
    })

    // console.log("🚀 ~ CrewRoute ~ error:", error)

    useEffect(() => {
        if (error) {
            // Show a toast notification
            toast({
                title: 'Error',
                description: 'An error occurred while fetching the route.',
                variant: 'destructive',
            });

            queryClient.invalidateQueries({ queryKey: ['routeList', user.crewMemberId] })

            // Navigate to a different page, e.g., the home page
            navigate('/crew/route');
        }
    }, [error, navigate]);

    


    const { data: isFeatureAvailable, isSuccess } = useQuery<boolean>({
        queryKey: ['crewViewJobFeatureRestrictions', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.timeTracking,
            ),
    })



    const onSuccess = (data: RouteType) => {
        setRoute(data)
        if (data.encodedPolyline != '') {
            const profiles = data.jobGroup
                ?.map((profile) => profile.job?.quote.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiles,
                encodedPolyline: data.encodedPolyline!,
            }
            setPolylineList(polylines)
        }
    }

    useEffect(() => {
        if (data) {
            onSuccess(data)
        }
    }, [data])

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords
                        setLatitude(latitude)
                        setLongitude(longitude)
                    },
                    () => {},
                )
            }
        }

        getLocation()
    }, [])

    // const { data: estimatedTime } = useQuery<unknown, AxiosError, string[]>({
    //     queryKey: ['route'],
    //     queryFn: () =>
    //         getEstimatedTime({
    //             routeId: data?.routeId,
    //             foremanLat: latitude,
    //             foremanLng: longitude,
    //         }),
    //     enabled: latitude != 0,
    // })

    const handleTabChange = (value: string) => {
        setSelectedTab(value)
    }

    useEffect(() => {
        setIsPolylineLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        if (isSuccess) {
            setRestrictionModal(!isFeatureAvailable)
        }
    }, [isSuccess, isFeatureAvailable])

    if (isLoading)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    const isUserForeman = crewMemberId === data?.crew?.foreMan?.crewMemberId

    return (
        <div className='w-full h-full overflow-hidden'>
            <div className='fixed w-full max-w-[1024px] top-0 z-20'>
                <BackButtonTopBar
                    title="Back to Route List"
                    options={isUserForeman}
                    redirectTo='/crew/route'
                    showUpgradeBadge={!isFeatureAvailable}
                    isLoading={false}
                />
            </div>
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            {!data && (
                <div className='mx-4 my-10'>
                    <div className='flex justify-center rounded-md bg-white'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-8 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white mt-4'>
                        {' '}
                        {'No Jobs Assigned Today'}{' '}
                    </p>
                </div>
            )}

            {data && (
                <div className='h-full mt-10 pt-10 '>
                    <Tabs
                        defaultValue='map'
                        onValueChange={handleTabChange}
                        className=' w-[40%] mx-auto h-[5%] '
                    >
                        <TabsList className='grid w-full  grid-cols-2 bg-zentive-blue-dark rounded-sm'>
                            <TabsTrigger
                                value='map'
                                className='text-sm rounded-sm px-6 py-1 data-[state=active]:bg-transparent data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:border data-[state=inactive]:border-zentive-blue-dark data-[state=inactive]:text-zentive-blue-dark'
                            >
                                {t('Map')}
                            </TabsTrigger>
                            <TabsTrigger
                                value='list'
                                className='text-sm rounded-sm px-6 py-1 data-[state=active]:bg-transparent data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:border data-[state=inactive]:border-zentive-blue-dark data-[state=inactive]:text-zentive-blue-dark'
                            >
                                {t('List')}
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                    <div className='text-white mt-6 text-left ml-10  font-semibold h-[5%]'>
                        {t('Today')}{' '}
                    </div>
                    <div className=' h-[80%] pb-[70px]'>
                        {selectedTab === 'map' && <WrappedCrewRoutingMap />}
                        {/* {selectedTab === 'list' && <JobList estimatedArrival={estimatedTime} />} */}
                        {selectedTab === 'list' && <JobList />}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CrewRoute
