import { GetJobsByCrewMemberIdParams, getJobsByCrewMemberId } from '@/api/job'
import { CreateJobType, JobListType } from '@/api/job/schema'
import SearchBar from '@/components/InputSearchBox'
import { userAtom } from '@/store/auth'
import { crewjobSearchKeywordAtom, crewjobSearchOpenAtom } from '@/store/crew'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { GoArrowLeft } from 'react-icons/go'
import { useNavigate } from 'react-router-dom'
import emptySearchPlaceholder from '@/assets/private/empty-search.png'
import { useTranslation } from 'react-i18next'
import { USER_STATUS } from '@/constants'

const JobSearch = () => {
    const { t } = useTranslation('view-job')
    const navigate = useNavigate()
    const setIsSearchToggle = useSetAtom(crewjobSearchOpenAtom)
    const [keyword, setKeyword] = useAtom(crewjobSearchKeywordAtom)
    const [jobs, setJobs] = useState<CreateJobType[] | null>(null)

    const user = useAtomValue(userAtom)

    const { mutate: getJobsByEmployeeMu } = useMutation<
        JobListType,
        AxiosError,
        GetJobsByCrewMemberIdParams
    >({
        mutationFn: (data) => getJobsByCrewMemberId(data),
        onSuccess: (data) => {
            setJobs(data?.content)
        },
    })

    useEffect(() => {
        if (keyword !== '' && !keyword.startsWith(' ') && keyword !== undefined) {
            getJobsByEmployeeMu({
                businessId: user?.businessId ?? '',
                profileId: user?.profileId ?? '',
                crewMemberId: user?.crewMemberId ?? '',
                keyword: keyword,
                status: USER_STATUS.ACTIVATED,
                startDate: '',
                endDate: '',
            })
        } else {
            getJobsByEmployeeMu({
                businessId: user?.businessId ?? '',
                profileId: user?.profileId ?? '',
                crewMemberId: user?.crewMemberId ?? '',
                keyword: '!@#$%^&',
                status: USER_STATUS.ACTIVATED,
                startDate: '',
                endDate: '',
            })
        }
    }, [keyword])

    return (
        <div className='z-50 w-full h-screen bg-white'>
            <div className='relative top-0 mx-auto z-20 bg-white'>
                <div className='flex flex-row border border-b py-2 px-2 gap-x-5'>
                    <div>
                        <GoArrowLeft
                            className='w-6 h-6 cursor-pointer mt-1'
                            onClick={() => {
                                navigate('/crew/jobs')
                                setIsSearchToggle(false)
                            }}
                        />
                    </div>
                    <div>
                        <SearchBar
                            placeHolder={keyword === '' ? t('Customer Name') : ''}
                            className='w-[250px] h-[42px] py-2 pl-10 pr-4 border border-gray-500 rounded-md focus:outline-none'
                            onSearchChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                        />
                    </div>
                </div>
                {jobs &&
                    jobs?.length >= 1 &&
                    jobs?.map((job, index) => (
                        <div
                            key={index}
                            role='button'
                            onClick={() => {
                                navigate(`/crew/job/${job?.jobId}`)
                                setKeyword('')
                            }}
                            className='flex flex-row border border-b py-4 px-2 gap-x-5'
                        >
                            {' '}
                            <CiSearch className='w-6 h-6' />{' '}
                            <p>
                                {job?.quote?.profile?.firstName +
                                    ' ' +
                                    job?.quote?.profile?.lastName}
                            </p>
                        </div>
                    ))}

                {jobs && jobs?.length === 0 && (
                    <div className='rounded-sm px-4'>
                        <div className='flex justify-center'>
                            <img
                                src={emptySearchPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-16 h-44'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-zentive-green-dark font-semibold'>
                            {' '}
                            {t('No Results Found')}{' '}
                        </p>
                        <p className='pb-4 text-center text-gray-900'>
                            {' '}
                            {t(`Please try again with a different keyword or phrase.`)}{' '}
                        </p>
                    </div>
                )}

                {!jobs && (
                    <div className='rounded-sm px-4'>
                        <div className='flex justify-center'>
                            <img
                                src={emptySearchPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-16 h-44'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-zentive-green-dark font-semibold'>
                            {' '}
                            {t('No Results Found')}{' '}
                        </p>
                        <p className='pb-4 text-center text-gray-900'>
                            {' '}
                            {t(`Please try again with a different keyword or phrase.`)}{' '}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default JobSearch
