import { getTaxRates } from '@/api/tax'
import { PaginationType } from '@/components/Pagination/schema'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { TaxType } from '@/api/tax/schema'
import { Pagination } from '@/components/Pagination'
import SearchBar from '@/components/InputSearchBox'
import { Button } from '@/components/Button'
import DeleteTaxRateModal from './DeleteTaxRateModal'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'
import emptyTaxRate from '@/assets/private/emptytaxrate.svg'

export const TaxRateTable: React.FC = () => {
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const user = useViewedAsUser()
    const navigate = useNavigate()

    const [searchVal, setSearchVal] = useState<string>('')

    const { data: taxRates, isLoading } = useQuery({
        queryKey: ['taxRates', pagination, searchVal],
        queryFn: () => getTaxRates(user?.businessId, searchVal, pagination),
    })

    const debouncedSearch = (val: string) => {
        setTimeout(() => {
            setSearchVal(val)
        }, 500)
    }

    return (
        <section className='flex flex-col gap-5'>
            <BreadCrumbs titleName='Tax Management' />
            {(taxRates && taxRates?.content?.length > 0) || searchVal !== '' ? (
                <div>
                    <div className='flex flex-row w-full space-x-16 items-start text-left justify-between'>
                        <SearchBar
                            placeHolder='Search for...'
                            onSearchChange={(e) => debouncedSearch(e.target.value)}
                        />
                        <div className=' items-end'>
                            {taxRates?.content && (
                                <Button
                                    type='button'
                                    onClick={() => navigate('/settings/tax/create-tax-rate')}
                                    className='border bg-zentive-green-dark text-white px-8 py-5'
                                >
                                    + Add Item
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className='bg-white mt-5'>
                        <Table>
                            <TableHeader>
                                <TableRow className='whitespace-nowrap'>
                                    <TableHead className='font-semibold text-zentive-gray-medium text-base px-10'>
                                        Name
                                    </TableHead>
                                    <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                        Description
                                    </TableHead>
                                    <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                        Type
                                    </TableHead>
                                    <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                        Tax Rates
                                    </TableHead>
                                    <TableHead className='font-semibold text-zentive-gray-medium text-base text-center'>
                                        Actions
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {isLoading && <AppSkeletonLoadingState />}
                                {!isLoading &&
                                    taxRates?.content?.map((data: TaxType) => (
                                        <TableRow key={data.name} className='whitespace-nowrap'>
                                            <TableCell className='flex-row items-start text-start text-zentive-black text-base px-10'>
                                                {data.name}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start text-zentive-black max-w-[15rem] text-base overflow-hidden text-ellipsis'>
                                                {data.description}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start text-zentive-black text-base capitalize'>
                                                {data.taxType}
                                            </TableCell>
                                            <TableCell className='flex-row items-start text-start text-zentive-black text-base capitalize'>
                                                <span className='flex flex-col gap-1'>
                                                    <p>Products : {data.prodPct ?? 0} %</p>
                                                    <p>Services : {data.servicePct ?? 0} %</p>
                                                    <p>Expenses : {data.expensePct ?? 0} %</p>
                                                </span>
                                            </TableCell>
                                            <TableCell className='flex flex-row justify-center text-zentive-black text-base capitalize'>
                                                <DeleteTaxRateModal taxRateId={data.taxId ?? ''} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>

                    <div className='mt-5'>
                        <Pagination
                            itemsPerPage={taxRates?.meta?.pageSize ?? 0}
                            totalRecords={taxRates?.meta?.totalRecords ?? 0}
                            setPagination={setPagination}
                            pagination={pagination}
                        />
                    </div>
                </div>
            ) : (
                <div className='bg-white flex flex-col items-center justify-center gap-4 p-24'>
                    <img src={emptyTaxRate} />
                    <p className='text-2xl text-zentive-green-dark font-bold'>
                        No Tax Rates to Display
                    </p>
                    <p className='text-base'>Looks like you haven't added any tax rates yet.</p>
                    <p className='text-base'>Click the button below to add.</p>.
                    <Button
                        type='button'
                        onClick={() => navigate('/settings/tax/create-tax-rate')}
                        className='border bg-zentive-green-dark text-white px-8 py-5 -mt-5'
                    >
                        + Add Item
                    </Button>
                </div>
            )}
        </section>
    )
}
