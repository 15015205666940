import { Lead, SortCriterion } from '@/api/lead/schema'
import { atom } from 'jotai'

const INITIAL_SORT_CRITERIA: SortCriterion[] = [{ column: 'created_at', order: 'DESC' }]

export const leadSortCriterionAtom = atom<SortCriterion[]>(INITIAL_SORT_CRITERIA)
export const leadToUpdateAtom = atom<string | null>(null)
export const selectedLeadIdsAtom = atom<Array<string>>([])
export const selectedLeadsAtom = atom<Lead[]>([])
export const viewedLeadAtom = atom<Lead | null>(null)
export const toExportLeadAtom = atom<Lead[]>([])

leadSortCriterionAtom.onMount = (set) => {
    return () => set(INITIAL_SORT_CRITERIA)
}

leadToUpdateAtom.onMount = (set) => {
    return () => set(null)
}

// selectedLeadsAtom.onMount = (set) => {
//     return () => set([])
// }

selectedLeadIdsAtom.onMount = (set) => {
    return () => set([])
}
