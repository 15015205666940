import { ProductServiceType } from '@/api/quoting/schema'
import { Card, CardContent, CardHeader } from '@/components/Card'
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/Table'
import { displayCurrency } from '@/utils/helper'
import { FC } from 'react'

type ProductServiceWithQuoteProps = {
    productServiceGroup: ProductServiceType[] | undefined
}

const ProductServiceWithQuote: FC<ProductServiceWithQuoteProps> = ({ productServiceGroup }) => {
    const isArrayWithContent =
        productServiceGroup && Array.isArray(productServiceGroup) && productServiceGroup?.length > 0

    if (!isArrayWithContent) {
        return null
    }

    const total = productServiceGroup
        .reduce((accumulator, item) => {
            const itemTotal = item?.cost ?? 0

            return accumulator + itemTotal
        }, 0)
        .toFixed(2)

    return (
        <Card className='rounded-none'>
            <CardHeader className='bg-zentive-bg-100 h-[55px] flex flex-row items-center justify-between w-full text-base font-semibold'>
                Product / Service
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow className='hover:bg-transparent'>
                            <TableHead variant='default'>Name</TableHead>
                            <TableHead variant='default'>Quantity</TableHead>
                            <TableHead variant='default'>Price</TableHead>
                            <TableHead variant='default'>Markup %</TableHead>
                            <TableHead variant='default'>Total</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {productServiceGroup?.map((item) => (
                            <TableRow className='hover:bg-transparent' key={item.name}>
                                <TableCell className='px-0 text-left'>{item.name}</TableCell>
                                <TableCell className='px-0 text-right'>
                                    {item.quantity && item.quantity > 1 ? item.quantity : 1}
                                </TableCell>
                                <TableCell className='px-0 text-right'>
                                    ${(item?.unitPrice || 0).toFixed(2)}
                                </TableCell>
                                <TableCell className='px-0 text-right'>
                                    {item?.markup || 0}%
                                </TableCell>
                                <TableCell className='px-0 text-right'>
                                   {displayCurrency(item.cost)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter className='bg-transparent'>
                        <TableRow className='hover:bg-transparent'>
                            <TableCell colSpan={5} className='px-0 text-right font-semibold'>
                                ${total}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </CardContent>
        </Card>
    )
}

export default ProductServiceWithQuote
