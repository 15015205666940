import { axiosInstance } from '../axiosInstance'
import { PaginationType } from '@/components/Pagination/schema'
import {
    BulkDeleteRouteType,
    CurrentPositionType,
    CustomersRouteByRouteIdType,
    JobRoutingType,
    OptimizeGroupsSchemaType,
    OwnerForemanProfileType,
    RouteListType,
    RouteOptimizeType,
    RouteType,
} from './schema'
import { CreateJobType } from '../job/schema'
import { SortParams } from '../job'

export const getAllRoutes = async (
    businessId: string,
    keyword: string,
    p: PaginationType,
    sort: SortParams,
): Promise<RouteListType> => {
    const { page, pageSize } = p

    const params = {
        keyword: keyword,
        businessId: businessId,
        page: page,
        pageSize: pageSize,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const res = await axiosInstance.get(`/routes`, { params })

    return res.data
}

export const getJobsInRoute = async (params: JobRoutingType) => {
    const res = await axiosInstance.post(`/jobs/routing/list`, params)
    return res.data.content
}

export const getJobsPolyline = async (params: CreateJobType[]) => {
    const res = await axiosInstance.post(`/routes/encoded-polyline`, params)

    return res.data.content
}

// using RA's api
export const getProfilesPolyline = async (params: RouteOptimizeType[]) => {
    const res = await axiosInstance.post(`/routes/encoded-profile-polyline`, params)
    return res.data.content as RouteOptimizeType[]
}

export const saveRouting = async (route: RouteType) => {
    const res = await axiosInstance.post(`/routes/create`, route)
    return res.data
}

export const getCounts = async (businessId: string) => {
    const res = await axiosInstance.get(`/routes/counts/${businessId}`)
    return res.data.content
}

export const getOwnerForeman = async (businessId: string) => {
    const res = await axiosInstance.get(`/routes/profiles/${businessId}`)
    return res.data.content as OwnerForemanProfileType[]
}

export const getCustomersRoute = async (businessId: string) => {
    const res = await axiosInstance.get(`/routes/customers/${businessId}`)
    return res.data.content as RouteOptimizeType[]
}

export const getJobsOptimizePolyline = async (optimizeGroups: OptimizeGroupsSchemaType) => {
    // const res = await axiosInstance.post(`/routes/optimize/encoded-polyline/ors`, optimizeGroups)
    const res = await axiosInstance.post(`/routes/optimize/encoded-jobs-polyline`, optimizeGroups)
    return res.data.content as RouteOptimizeType[]
}

export const saveOptimizeGroup = async (route: RouteOptimizeType[]) => {
    const res = await axiosInstance.post(`/routes/optimize-group`, route)
    return res.data.content
}

export const getRoute = async (routeId: string) => {
    const res = await axiosInstance.get(`/routes/${routeId}`)
    return res.data.content
}

export const getRouteWithCrewId = async (
    routeId: string,
    crewMemberId: string,
    profileId: string,
) => {
    const res = await axiosInstance.get(
        `/routes/single/crew?crewMemberId=${crewMemberId}&profileId=${profileId}&routeId=${routeId}`,
    )
    return res.data.content
}

export const getRouteByCrewMemberId = async (crewMemberId: string, profileId: string) => {
    const res = await axiosInstance.get(
        `/routes/crew?crewMemberId=${crewMemberId}&profileId=${profileId}`,
    )
    return res.data.content
}

export const updateCurrentLocation = async (currentPosition: CurrentPositionType) => {
    const res = await axiosInstance.post(`routes/save-position`, currentPosition)

    return res
}

export const getCurrentLocation = async (routeId: string) => {
    const res = await axiosInstance.get(`/routes/current-location/${routeId}`)

    return res.data.content as CurrentPositionType
}

export const getEstimatedTime = async (currentPosition: CurrentPositionType) => {
    const res = await axiosInstance.post(`/routes/duration`, currentPosition)

    return res.data.content
}

export const bulkDeleteRoutes = async (data: BulkDeleteRouteType) => {
    const res = await axiosInstance.post('/routes/bulk-delete', data)

    return res.data.content
}

export const getJobsRouteId = async (routeId: string) => {
    const res = await axiosInstance.get(`/routes/jobs/${routeId}`)
    return res.data.content
}

export const getJobsRoute = async (routeId: string) => {
    const res = await axiosInstance.get(`/route/${routeId}`)
    return res.data.content
}

export const updateRouting = async (route: RouteType) => {
    const res = await axiosInstance.post(`/routes/update`, route)
    return res.data
}

export const getCustomersRouteByRouteId = async (
    routeId: string,
    businessID: string,
): Promise<CustomersRouteByRouteIdType> => {
    const res = await axiosInstance.get(`/routes/customers-route/${routeId}/${businessID}`)

    return res.data.content
}

export const validateRouteSchedule = async (crewId: string, date: string): Promise<boolean> => {
    const res = await axiosInstance.get(`/routes/validate-schedule`, {
        params: {
            crewId: crewId,
            date: date,
        },
    })

    return res.data.content
}
