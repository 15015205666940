import { QuoteType } from '@/api/quoting/schema'
import { CardTitle } from '@/components/Card'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { useFieldArray, useFormContext } from 'react-hook-form'
import repeatGrid from '@/assets/private/repeat_grid.svg'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { getRequestServices } from '@/api/service'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { RequestServiceType } from '@/api/service/schema'
import { AxiosError } from 'axios'
import { quoteContentAtom } from '@/store/owner'
import { CiTrash } from 'react-icons/ci'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { startTransition, useEffect, useState } from 'react'
import AddProductService from '@/pages/common/Schedule/Job/AddProductService'
import { isNoSelectedServicesQuotingAtom } from '@/store/financial'
import { DROPDOWN_LABELS } from '@/constants'
import { displayCurrency } from '@/utils/helper'
import { isNaN } from 'lodash'

const ContentService = () => {
    const setQuoteContent = useSetAtom(quoteContentAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const [, setSelectedService] = useState<string>('')
    const [IsSelectService, setIsSelectService] = useState<string[]>([])
    const [selectedServices, setSelectedServices] = useState<string[]>([])
    const [isProductsModalOpen, setIsProductsModalOpen] = useState<boolean>(false)
    const { control, setValue, register, getValues, watch } = useFormContext<QuoteType>()
    const [currentFocus, setCurrentFocus] = useState<number | null>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const setIsNoSelectedServices = useSetAtom(isNoSelectedServicesQuotingAtom)

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'productServiceGroup',
    })

    const { data: services } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType'],
        queryFn: () => getRequestServices(user?.businessId as string),
    })

    const handleOnChange = (service: string, index: number) => {
        const selectedServiceItem = [...IsSelectService]
        selectedServiceItem[index] = service
        setIsSelectService(selectedServiceItem)

        setIsOpen(false)

        if (service === '+ Add Product Or Services') {
            setIsProductsModalOpen(true)
            setSelectedService('')
        }

        if (service === DROPDOWN_LABELS.servicesOrProducts) {
            setSelectedServices((prevSelectedServices) => {
                const updatedSelectedServices = [...prevSelectedServices]
                const selectedService = updatedSelectedServices[index]
                if (selectedService !== DROPDOWN_LABELS.servicesOrProducts) {
                    updatedSelectedServices[index] = DROPDOWN_LABELS.servicesOrProducts
                }
                return updatedSelectedServices
            })

            setValue(`productServiceGroup.${index}.quantity`, 0)
            setValue(`productServiceGroup.${index}.productServiceId`, '')
            setValue(`productServiceGroup.${index}.unitPrice`, 0)
            setValue(`productServiceGroup.${index}.markup`, 0)
            setValue(`productServiceGroup.${index}.cost`, 0)
            setValue(`productServiceGroup.${index}.description`, '')
            setValue(`productServiceGroup.${index}.name`, '')
            setValue(`productServiceGroup.${index}.type`, '')
            setIsNoSelectedServices(true)
        } else {
            setSelectedServices((prevSelectedServices) => {
                const updatedSelectedServices = [...prevSelectedServices]
                updatedSelectedServices[index] = service
                return updatedSelectedServices
            })

            let serv = JSON.parse(service)
            const markup = serv.markup || 0
            setValue(`productServiceGroup.${index}.quantity`, 1)
            setValue(`productServiceGroup.${index}.productServiceId`, serv.productServiceId)
            setValue(`productServiceGroup.${index}.unitPrice`, serv.unitPrice)
            setValue(`productServiceGroup.${index}.markup`, markup)
            setValue(
                `productServiceGroup.${index}.cost`,
                Number(
                    (serv.unitPrice + (serv.unitPrice * (serv.markup as number)) / 100).toFixed(2),
                ),
            )
            setValue(`productServiceGroup.${index}.description`, serv.description)
            setValue(`productServiceGroup.${index}.name`, serv.name)
            setValue(`productServiceGroup.${index}.type`, serv.type)

            handleOnMarkupChange(index, markup)
            setIsNoSelectedServices(false)
        }

        let group = getValues(`productServiceGroup`)

        setQuoteContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!)?.reduce((a, b) => a + b) + prevVal.laborCost
                : 0.0,
        }))

        setQuoteContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnPriceChange = (index: number, price: number) => {
        var markup = getValues(`productServiceGroup.${index}.markup`) || 0
        const quantity = getValues(`productServiceGroup.${index}.quantity`)
        var totalPrice = (quantity ? quantity : 1) * (price ?? 0)
        let group = getValues(`productServiceGroup`)
        setValue(`productServiceGroup.${index}.unitPrice`, price ?? 0)

        if (isNaN(totalPrice) || totalPrice === undefined) {
            totalPrice = 0.0
        }

        if (isNaN(markup)) {
            markup = 0.0
        }

        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup) / 100 + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )
        setQuoteContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!).reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setQuoteContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnMarkupChange = (index: number, markup: number) => {
        const price = getValues(`productServiceGroup.${index}.unitPrice`)
        const quantity = getValues(`productServiceGroup.${index}.quantity`)
        const totalPrice = (quantity ? quantity : 1) * price
        const validMarkup = markup !== undefined && !isNaN(markup) ? markup : 0
        let group = getValues(`productServiceGroup`)
        setValue(`productServiceGroup.${index}.markup`, markup)

        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * validMarkup) / 100 + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )

        setQuoteContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost!).reduce((a, b) => a + b) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))
        setQuoteContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice!).reduce((a, b) => a + b)
                : 0.0,
        }))
    }

    const handleOnQuantityChange = (index: number, quantity: number, price: number) => {
        const markup = getValues(`productServiceGroup.${index}.markup`) as number
        const totalPrice = quantity * price
        let group = getValues(`productServiceGroup`)

        setValue(`productServiceGroup.${index}.quantity`, quantity, {
            shouldValidate: true,
        })

        setValue(
            `productServiceGroup.${index}.cost`,
            Number(((totalPrice * markup!) / 100 + totalPrice).toFixed(2)),
            {
                shouldValidate: true,
            },
        )

        setQuoteContent((prevVal) => ({
            ...prevVal,
            subTotal: group
                ? group?.map((datum) => datum.cost || 0).reduce((a, b) => a + b, 0) +
                  prevVal.laborCost +
                  prevVal.totalExpense
                : 0.0,
        }))

        setQuoteContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: group
                ? group?.map((datum) => datum.unitPrice || 0).reduce((a, b) => a + b, 0)
                : 0.0,
            totalProductsAndServices: group
                ? group.map((datum) => datum.unitPrice || 0).reduce((a, b) => a + b, 0)
                : 0.0,
        }))
    }

    const handleOnRemove = (index: number) => {
        const newSelectedServices = [...selectedServices]
        newSelectedServices.splice(index, 1)
        setSelectedServices(newSelectedServices)

        const areAnyServicesSelected = newSelectedServices.some((service) => service !== 'Select')

        const cost = getValues(`productServiceGroup.${index}.cost`)
        const price = getValues(`productServiceGroup.${index}.unitPrice`)

        setQuoteContent((prevVal) => ({
            ...prevVal,
            subTotal: Number((prevVal.subTotal - cost!).toFixed(2)),
            totalUnitPrice: Number((prevVal.totalUnitPrice - price!).toFixed(2)),
            laborCost: prevVal.laborCost,
        }))

        if (!areAnyServicesSelected) {
            setQuoteContent((prevVal) => ({
                ...prevVal,
                subTotal: prevVal.subTotal - prevVal.laborCost,
            }))
        }

        remove(index)
    }

    const totalServicesCost = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        return total + (cost ? cost : 0)
    }, 0)

    const totalProductsAndServicesPrice = fields.reduce((total, _, index) => {
        const unitPrice = getValues(`productServiceGroup.${index}.unitPrice`) as number
        const quantity = getValues(`productServiceGroup.${index}.quantity`) as number
        return total + (unitPrice ? unitPrice : 0) * quantity
    }, 0)

    const totalProduct = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        const type = getValues(`productServiceGroup.${index}.type`) as string
        return type === 'product' ? total + (cost ? cost : 0) : total
    }, 0)

    const totalService = fields.reduce((total, _, index) => {
        const cost = getValues(`productServiceGroup.${index}.cost`) as number
        const type = getValues(`productServiceGroup.${index}.type`) as string
        return type === 'service' ? total + (cost ? cost : 0) : total
    }, 0)

    const handleFocus = (index: number) => {
        setCurrentFocus(index)
        setIsOpen(true)
    }

    const handleBlur = () => {
        setCurrentFocus(null)
        setIsOpen(false)
    }

    useEffect(() => {
        startTransition(() => {
            setIsNoSelectedServices(true)
        })
    }, [])

    useEffect(() => {
        startTransition(() => {
            setQuoteContent((prevVal) => ({
                ...prevVal,
                totalProductsAndServices: Number(totalProductsAndServicesPrice.toFixed(2)),
                totalServicePrice: Number(totalServicesCost.toFixed(2)),
                totalProduct: Number(totalProduct.toFixed(2)),
                totalService: Number(totalService.toFixed(2)),
            }))
        })
    }, [totalServicesCost])

    return (
        <>
            <div className='flex flex-row mr-14'>
                <CardTitle className='text-lg justify-start w-1/2'>Services and Products</CardTitle>
                <div className='w-1/2 flex justify-end'>
                    <button
                        type='button'
                        className='text-zentive-green-dark font-semibold'
                        onClick={() =>
                            append({
                                productServiceId: '',
                                name: '',
                                cost: 0,
                                markup: 0,
                                unitPrice: 0,
                                description: '',
                                quantity: 0,
                                type: '',
                            })
                        }
                    >
                        + Add Service
                    </button>
                </div>
            </div>
            <div className='overflow-x-scroll green-scrollbar'>
                {fields?.map((_, index) => (
                    <div className='flex flex-row relative' key={`field-${index}`}>
                        <div className='flex justify-left my-6 mr-4'>
                            <img src={repeatGrid} alt={'Repeat Grid'} className='h-10 w-10' />
                        </div>
                        <div className='flex flex-col w-full my-4 mr-14'>
                            <div className='flex flex-row w-full mb-6 justify-between'>
                                <div className='relative z-10'>
                                    <select
                                        name='row'
                                        onFocus={() => handleFocus(index)}
                                        onBlur={handleBlur}
                                        onChange={(val) => handleOnChange(val.target.value, index)}
                                        onMouseDown={() => setIsOpen((prev) => !prev)}
                                        className='flex 2xl:w-96 xl:w-60 lg:w-48 font-sans-pro text-base pl-3 pr-8 h-11 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm mr-4 bg-white appearance-none'
                                    >
                                        <option className='text-lg'>
                                            {DROPDOWN_LABELS.servicesOrProducts}
                                        </option>
                                        {services?.services?.map((data) => (
                                            <option
                                                value={JSON.stringify(data)}
                                                key={data.productServiceId}
                                                hidden={selectedServices.includes(
                                                    JSON.stringify(data),
                                                )}
                                                className='text-lg'
                                            >
                                                {data.name}
                                            </option>
                                        ))}
                                        <option
                                            className='text-zentive-green-dark font-semibold'
                                            key={`add-label`}
                                        >
                                            + Add Product Or Services
                                        </option>
                                    </select>
                                    <div className='absolute inset-y-0 right-4 flex items-center pr-3 pointer-events-none'>
                                        {isOpen && index === currentFocus ? (
                                            <RiArrowDropUpLine className='h-5 w-5 text-gray-500' />
                                        ) : (
                                            <RiArrowDropDownLine className='h-5 w-5 text-gray-500' />
                                        )}
                                    </div>
                                </div>

                                <FormField
                                    control={control}
                                    disabled={
                                        !getValues(`productServiceGroup.${index}.productServiceId`)
                                    }
                                    defaultValue={1}
                                    {...register(`productServiceGroup.${index}.quantity`)}
                                    render={({ field }) => (
                                        <FormItem className='z-10'>
                                            <FormControl>
                                                <Input
                                                    className='2xl:w-40 xl:w-24 lg:w-20 mr-4 flex justify-center items-center text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm'
                                                    placeholder='Quantity*'
                                                    type='number'
                                                    {...field}
                                                    onChange={(val) =>
                                                        handleOnQuantityChange(
                                                            index,
                                                            parseInt(val.target.value),
                                                            watch().productServiceGroup[index]
                                                                ?.unitPrice,
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    disabled={
                                        !getValues(`productServiceGroup.${index}.productServiceId`)
                                    }
                                    {...register(`productServiceGroup.${index}.unitPrice`)}
                                    render={({ field }) => (
                                        <FormItem className='z-10'>
                                            <FormControl>
                                                <Input
                                                    className='2xl:w-64 xl:w-40 lg:w-32 mr-4 flex justify-center items-center text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right focus:text-left'
                                                    placeholder='Price* ($)'
                                                    type='number'
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                    {...field}
                                                    onChange={(val) =>
                                                        handleOnPriceChange(
                                                            index,
                                                            parseFloat(val.target.value),
                                                        )
                                                    }
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    defaultValue={6}
                                    {...register(`productServiceGroup.${index}.markup`)}
                                    render={({ field }) => (
                                        <FormItem className='z-10'>
                                            <FormControl>
                                                <Input
                                                    className='2xl:w-52 xl:w-32 lg:w-24 mr-4 flex justify-center items-center text-base  h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right focus:text-left'
                                                    placeholder='Markup % (Optional)'
                                                    disabled={
                                                        !services?.services
                                                            ?.map((data) => JSON.stringify(data))
                                                            ?.includes(IsSelectService[index])
                                                    }
                                                    type='text'
                                                    {...field}
                                                    onChange={(val) => {
                                                        const newValue = val.target.value.replace(
                                                            '%',
                                                            '',
                                                        )
                                                        handleOnMarkupChange(
                                                            index,
                                                            newValue === ''
                                                                ? 0
                                                                : parseFloat(newValue),
                                                        )
                                                    }}
                                                    value={
                                                        field.value != 0
                                                            ? typeof field.value === 'number' &&
                                                              !isNaN(field.value)
                                                                ? `${field.value}%`
                                                                : ''
                                                            : ''
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={control}
                                    {...register(`productServiceGroup.${index}.cost`)}
                                    render={({ field }) => (
                                        <FormItem className='z-10'>
                                            <FormControl>
                                                <Input
                                                    className='2xl:w-52 xl:w-32 lg:w-24 flex justify-center items-center text-base h-11 border border-black disabled:bg-zentive-gray-light text-gray-900 rounded-sm text-right'
                                                    placeholder='Total'
                                                    type='text'
                                                    disabled={true}
                                                    {...field}
                                                    value={
                                                        field.value != 0
                                                            ? typeof field.value === 'number' &&
                                                              !isNaN(field.value)
                                                                ? displayCurrency(field.value)
                                                                : displayCurrency(0)
                                                            : displayCurrency(0)
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (
                                                            e.key === '-' ||
                                                            e.key === '+' ||
                                                            e.key === 'e' ||
                                                            e.key === 'E'
                                                        ) {
                                                            e.preventDefault()
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <div className='flex'>
                                    {index !== 0 && (
                                        <button type='button' onClick={() => handleOnRemove(index)}>
                                            <CiTrash className='size-7 text-zentive-red-dark' />
                                        </button>
                                    )}
                                </div>
                            </div>

                            <div>
                                <FormField
                                    control={control}
                                    {...register(`productServiceGroup.${index}.description`)}
                                    render={({ field }) => (
                                        <FormItem className='z-10'>
                                            <FormControl>
                                                <Textarea
                                                    className='flex w-full pt-3 text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm'
                                                    placeholder='Description'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <hr className='border-b border-dashed border-zentive-gray-medium w-full mt-4 '></hr>
            <div className='flex mt-4'>
                <label className='font-semibold text-zentive-gray-medium px-4'>
                    Total of Services
                </label>
                <p className='ml-auto text-zentive-green-dark px-4'>
                    ${totalServicesCost.toFixed(2)}
                </p>
            </div>
            <AddProductService
                isOpen={isProductsModalOpen}
                setIsOpen={setIsProductsModalOpen}
                // setSelectedService={setSelectedService}
            />
        </>
    )
}

export default ContentService
