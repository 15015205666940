import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import { CreateTaxRateType, TaxRateListType, TaxType, UpdateTaxRateStatusType } from './schema'

export const createTaxRate = async (data: CreateTaxRateType) => {
    const response = await axiosInstance.post('/stripe/tax/create', data)

    return response.data
}

export const updateTaxRateStatus = async (data: UpdateTaxRateStatusType) => {
    const response = await axiosInstance.post('/stripe/tax/update', data)

    return response.data
}

export const getTaxRates = async (
    businessId: string,
    search: string,
    p: PaginationType,
): Promise<TaxRateListType> => {
    const response = await axiosInstance.get('/stripe/tax', {
        params: {
            page: p.page,
            pageSize: p.pageSize,
            search: search,
            businessId: businessId,
        },
    })

    return response.data
}

export const getTaxRateById = async (taxRateId: string): Promise<TaxType> => {
    const response = await axiosInstance.get(`/stripe/tax/${taxRateId}`)

    return response.data.content
}
