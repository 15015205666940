import { ReadJobType } from '@/api/job/schema'
import { Button } from '@/components/Button'
import { useTranslation } from 'react-i18next'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'

interface IConfirmationModal {
    job?: ReadJobType
    title: string
    text: string
    isOpen: boolean
    onClose: () => void
    onProceed: () => void
}

const CompleteJobModal = ({ job, title, text, isOpen, onClose, onProceed }: IConfirmationModal) => {
    const { t } = useTranslation(['common'])
    return (
        <div className='fixed bottom-0 w-full z-50 max-w-[1024px]'>
            <div
                className={`sticky transform ${
                    isOpen
                        ? 'translate-y-4 h-[90dvh] md:h-[90dvh] lg:h-[90dvh] 2xl:h-[85dvh]'
                        : 'translate-y-1/4 h-[0dvh]'
                } transition-all duration-500 bg-[#EBEBEB]`}
            >
                <div className='flex flex-col h-full'>
                    <div className='flex flex-col items-center justify-center bg-white border-b space-y-4 text-sm py-4'>
                        <div className='bg-zentive-red-light rounded-full py-4'>
                            <HiOutlineExclamationTriangle className='h-5 w-5 mx-5 text-red-500' />
                        </div>
                        <div className='px-10 text-center'>{title}</div>
                    </div>

                    {/* Job details with scroll */}
                    <div className='flex-grow overflow-y-auto w-full mx-auto bg-white px-8 py-4'>
                        <div className='flex flex-col border-b space-y-3 py-4'>
                            <p className='text-zentive-gray-medium font-semibold text-lg'>
                                {t('Job')}
                            </p>
                            {job?.quote?.productServiceGroup
                                ?.filter((service) => service?.type === 'service')
                                .map((service, index) => (
                                    <p key={index} className='text-sm'>
                                        {service?.name ?? ''}
                                    </p>
                                ))}
                        </div>
                        <div className='flex flex-col border-b space-y-3 py-4'>
                            <p className='text-zentive-gray-medium font-semibold text-lg'>
                                {t('List of Materials')}
                            </p>
                            <div className='flex flex-col gap-y-2'>
                                {job &&
                                job?.quote?.productServiceGroup?.filter(
                                    (product) => product?.type === 'product',
                                )?.length >= 1
                                    ? job?.quote?.productServiceGroup
                                          ?.filter((product) => product?.type === 'product')
                                          .map((product, index) => (
                                              <div
                                                  key={index}
                                                  className='flex flex-row justify-between'
                                              >
                                                  <p className='text-sm'>{product?.name ?? ''}</p>
                                                  <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                                  <p className='text-sm'>
                                                      $
                                                      {product?.unitPrice?.toLocaleString(
                                                          undefined,
                                                          {
                                                              maximumFractionDigits: 2,
                                                              minimumFractionDigits: 2,
                                                          },
                                                      ) ?? ''}
                                                  </p>
                                              </div>
                                          ))
                                    : '--'}
                            </div>
                        </div>
                        {job?.quote?.expense && job?.quote?.expense?.length >= 1 && (
                            <div className='flex flex-col border-b space-y-3 py-4'>
                                <p className='text-zentive-gray-medium font-semibold text-lg'>
                                    {t('Expenses')}
                                </p>
                                <div className='flex flex-col gap-y-2'>
                                    <div>
                                        <p className='text-zentive-gray-medium text-md my-2'>
                                            {t(`External (Included in client's invoice)`)}
                                        </p>
                                        {job?.quote?.expense
                                            ?.filter((item) => !item.isNotIncludedInInvoice)
                                            .map((exp, index) => (
                                                <div
                                                    key={index}
                                                    className='flex flex-row justify-between'
                                                >
                                                    <p className='text-sm'>{exp.accountName}</p>
                                                    <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                                    <p className='text-sm'>
                                                        $
                                                        {exp?.total.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        }) ?? ''}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                    <div>
                                        {job?.quote?.expense?.filter(
                                            (item) => item.isNotIncludedInInvoice,
                                        ).length >= 1 && (
                                            <p className='text-zentive-gray-medium text-md my-2'>
                                                {t(`Internal (Excluded in client's invoice)`)}
                                            </p>
                                        )}
                                        {job?.quote?.expense
                                            ?.filter((item) => item.isNotIncludedInInvoice)
                                            .map((exp, index) => (
                                                <div
                                                    key={index}
                                                    className='flex flex-row justify-between'
                                                >
                                                    <p className='text-sm'>{exp.accountName}</p>
                                                    <div className='flex-grow h-px bg-zentive-gray-light my-3 mx-4'></div>
                                                    <p className='text-sm'>
                                                        $
                                                        {exp?.total.toLocaleString(undefined, {
                                                            maximumFractionDigits: 2,
                                                            minimumFractionDigits: 2,
                                                        }) ?? ''}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Fixed buttons at the bottom */}
                    <div className='flex flex-col w-full items-center justify-center bg-[#EBEBEB] py-10 px-4'>
                        <Button
                            variant='default'
                            className='text-white w-full mb-4'
                            onClick={onProceed}
                        >
                            {text}
                        </Button>
                        <Button
                            variant='ghost'
                            className='w-full hover:none bg-white border border-zentive-green-dark'
                            onClick={onClose}
                        >
                            {t('Cancel')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompleteJobModal
