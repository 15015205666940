import { CardContent, CardTitle } from '@/components/Card'
import ContentService from './Service'
import Expense from '../../../Quoting/ViewQuote/CardContent/Expenses'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Textarea } from '@/components/TextArea'
import { InvoiceType } from '@/api/invoicing/schema'
import { SHOULD_VALIDATE_TRUE } from '@/constants'
import { QuoteType } from '@/api/quoting/schema'
import BillingAmount from './BillingAmount'

const AddInvoiceFromJobContent = () => {
    const [internalNotes, setInternalNotes] = useState('')
    const { setValue, watch } = useFormContext<InvoiceType>()

    const handleInternalNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target
        setInternalNotes(value)
        setValue('internalNotes', value, SHOULD_VALIDATE_TRUE)
    }

    return (
        <CardContent className='pt-9 pb-20 mx-5'>
            <ContentService />
            <Expense quote={watch() as QuoteType} />
            <BillingAmount />
            <div className='border border-[#EBEBEB] p-6 '>
                <CardTitle className='text-lg mb-6'>Internal notes</CardTitle>
                <Textarea
                    className='flex w-full pt-3 text-base h-24 border border-black disabled:bg-gray-100 text-gray-900 rounded-sm'
                    placeholder='Description'
                    value={internalNotes}
                    onChange={handleInternalNotesChange}
                />
            </div>
        </CardContent>
    )
}

export default AddInvoiceFromJobContent
