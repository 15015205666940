import { PaginationSchema } from '@/components/Pagination/schema'
import { z } from 'zod'

export const createDiscountSchema = z.object({
    discountID: z.string().optional(),
    subTypes: z.array(z.string()),
    businessId: z.string(),
    discountCode: z
        .string()
        .min(1, { message: 'Discount code is required' })
        .regex(/^\S*$/, { message: 'Discount code must not contain spaces' })
        .regex(
            /^[a-zA-Z0-9_-]+$/,
            'Must only contain letters, numbers and characters like (-) and (_).',
        ),
    name: z
        .string()
        .min(1, { message: 'Discount name is required' })
        .refine((value) => value.trim().length > 0, {
            message: 'Discount name must not be empty or whitespace',
        }),
    discountType: z.string().min(1, { message: 'Discount type is required' }),
    amount: z
        .number()
        .positive({ message: 'Discount amount must be greater than 0' })
        .refine((value) => value > 0, {
            message: 'Discount amount is required and must be positive',
        }),
    usageLimit: z
        .number()
        .int({ message: 'Usage limit must be an integer' })
        .positive({ message: 'Usage limit must be greater than 0' }),
    startDate: z
        .string()
        .min(1, { message: 'Start date is required' })
        .refine((value) => !isNaN(Date.parse(value)), {
            message: 'Start date must be a valid date',
        }), // Valid date check
    endDate: z
        .string()
        .min(1, { message: 'End date is required' })
        .refine((value) => !isNaN(Date.parse(value)), { message: 'End date must be a valid date' }), // Valid date check
})

export const createInvoiceDiscountSchema = createDiscountSchema.pick({
    businessId: true,
    name: true,
    discountType: true,
    amount: true,
    discountCode: true,
})

export const getDiscountsResSchema = z.object({
    discountId: z.string(),
    discountCode: z.string(),
    amount: z.number().positive(),
    startDate: z.string().datetime(), // Assuming the date will be in ISO format
    endDate: z.string().datetime(), // Assuming the date will be in ISO format
    eligibility: z.array(z.string()),
    usageLimit: z.number().int(),
    discountType: z.string(),
    name: z.string(),
})

export const discountListSchema = z.object({
    content: z.array(getDiscountsResSchema),
    meta: PaginationSchema,
})

export type CreateDiscountType = z.infer<typeof createDiscountSchema>
export type DiscountListType = z.infer<typeof discountListSchema>
export type DiscountRecordType = z.infer<typeof getDiscountsResSchema>
export type CreateInvoiceDiscountType = z.infer<typeof createInvoiceDiscountSchema>
