import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    ProfileSelfResponseType,
    profileBusinessSchema,
    SubscriptionListType,
    ProfileBusinessType,
    BusinessProfileType,
    OwnerStatusCount,
} from '../business/schema'
import { jwtDecoder } from '@/utils/helper'
import {
    InviteUserType,
    BulkProfileUpdateStatusType,
    CustomerProfileType,
    FullProfileType,
    BillingInfoType,
    ParamsFilterType,
    CustomerContactType,
    SubscriptionsInfoType,
    InviteEmployeeType,
    StripeSetupLaterType,
    RestrictionsType,
    CustomerProfileListType,
    ValidateCustomerInviteType,
    EncryptedProfileStatusType,
    InviteEmployeeViaCSVType,
    MailChimpIntegrationType,
    InviteCoOwnerType,
    BusinessOwnerLisType,
} from './schema'
import { FullAddress, GeocodeResult, fetchCoordinates, fetchTimezone } from '@/hooks/useGeocode'
import { NOT_FOUND_ERRORS, USER_STATUS } from '@/constants'
import { ResetPasswordType } from '../auth/schema'
import { AddBulkEmployeeType, EmployeeType } from '../employee/schema'
import { getStripeConnectStatus } from '../subscription'
import { AxiosError } from 'axios'
import { QuoteType } from '../quoting/schema'
import { SortParams } from '../job'
import { CustomerStatusCount } from '../customer/schema'

interface JwtPayload {
    user_id: string
}

export const getProfile = async (): Promise<FullProfileType> => {
    const response = await axiosInstance.get('/profiles/self')

    return response.data
}

export const getFullProfile = async (): Promise<FullProfileType> => {
    try {
        const response = await axiosInstance.get('/profiles/self')
        return response.data
    } catch (error) {
        throw error
    }
}

export const getProfileById = async (target: string): Promise<FullProfileType> => {
    const responseViewAsToken = await axiosInstance.get(`/profiles/view-as?target=${target}`)

    const decodedJwtToken = jwtDecoder(responseViewAsToken.data?.viewAsToken) as JwtPayload

    const userId = decodedJwtToken?.user_id

    const response = await axiosInstance.get('/profiles/' + userId)

    return response.data.content
}

export const bulkProfileStatusRestore = async (data: BulkProfileUpdateStatusType) => {
    const response = await axiosInstance.post(`/profiles/bulk-update/restore`, data)

    return response.data
}

export const bulkProfileStatusUpdate = async (data: BulkProfileUpdateStatusType) => {
    const response = await axiosInstance.post(`/profiles/bulk-update/status`, data)

    return response.data
}

export const checkForemanByProfileIds = async (data: BulkProfileUpdateStatusType) => {
    const response = await axiosInstance.post(`/crews/check-foreman`, data)

    return response.data
}

export const checkFreeTrialOwner = async (businessId: string) => {
    const res = await axiosInstance.get('profiles/free-trial-ended/' + businessId)

    return res.data.content
}

export const updateProfile = async (
    data: ProfileBusinessType,
): Promise<ProfileSelfResponseType> => {
    // Extract lat and lng here not on the form in case user updates location fields
    // Already applied on the latest develop branch

    //@TODO: Gmap lat lng is not being updated! To inform Dex

    const payload = profileBusinessSchema.parse(data)
    const coordinates = await fetchCoordinates(payload.address as FullAddress)
    const timeZone = await fetchTimezone(coordinates as GeocodeResult)

    const formData = new FormData()

    formData.append('profileId', payload.profileId as string)
    formData.append('businessId', payload.businessId as string)
    formData.append('addressId', payload.addressId as string)
    formData.append('firstName', payload.firstName as string)
    formData.append('lastName', payload.lastName as string)
    formData.append('email', payload.email as string)
    formData.append('phoneNumber', payload.phoneNumber as string)
    formData.append('businessWebsite', String(payload.business.businessWebsite))
    formData.append('businessName', payload.business.businessName as string)
    formData.append('streetAddress', payload.address.streetAddress as string)
    formData.append('streetTwoAddress', payload.address.streetTwoAddress as string)
    formData.append('city', payload.address.city as string)
    formData.append('state', payload.address.state as string)
    formData.append('zipCode', payload.address.zipCode as string)
    formData.append('timeOffset', timeZone?.rawOffset.toString() as string)
    formData.append('timezone', timeZone?.timeZoneId as string)

    if (typeof coordinates?.lat === 'number') {
        formData.append('gmapLat', String(coordinates.lat))
    }

    if (typeof coordinates?.lng === 'number') {
        formData.append('gmapLng', String(coordinates.lng))
    }

    if (payload.profileImage) {
        formData.append('profileImage', payload.profileImage)
    }

    try {
        const response = await axiosInstance.patch('/profiles/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    } catch (err) {
        console.error('Failed to update profile:', err)
        throw err
    }
}

export const createStripeCustomer = async (): Promise<string> => {
    const response = await axiosInstance.post(`stripe/customer`)
    return response.data.content
}

export const sendWelcomeEmail = async (email: string) => {
    const response = await axiosInstance.post(`sendgrid/welcome/${email}`)

    return response.data
}

export const getAllSubscribers = async (
    keyword: string,
    status: string,
    sortKeyword: string,
    p: PaginationType,
    sort: SortParams,
    filter?: ParamsFilterType,
): Promise<SubscriptionListType> => {
    const filterString = Object.entries(filter || {})
        .filter(([, value]) => value)
        ?.map(([key]) => key)
        .join('-')
    const response = await axiosInstance.get(
        `/profiles/?keyword=${keyword}&status=${status}&sort=${sortKeyword}&page=${p.page}&pageSize=${p.pageSize}&filter=${filterString}&sortColumn=${sort.sortBy}$sortDirection=${sort.sortOrder}`,
    )

    return response.data
}

export const getAllSubscribersCount = async (): Promise<OwnerStatusCount> => {
    const response = await axiosInstance.get(`/profiles/count`)
    return response.data
}

export const getAllCustomersCount = async (businesID: string): Promise<CustomerStatusCount> => {
    const response = await axiosInstance.get(`/profiles/customers/count?businessID=${businesID}`)
    return response.data
}

export const getBusinessProfile = async (
    profileId: string | undefined,
): Promise<ProfileBusinessType> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const response = await axiosInstance.get(`/profiles/${profileId}`)
    return response.data.content
}

export const updateBusinessProfile = async (
    data: BusinessProfileType | CustomerProfileType | ProfileBusinessType,
) => {
    const payload = profileBusinessSchema.parse(data)

    const coordinates = await fetchCoordinates(payload.address as FullAddress)
    const timeZone = await fetchTimezone(coordinates as GeocodeResult)
    const formData = new FormData()

    formData.append('profileId', payload.profileId as string)
    formData.append('businessName', payload.business.businessName as string)
    formData.append('lastName', payload.lastName as string)
    formData.append('firstName', payload.firstName as string)
    formData.append('email', payload.email as string)
    formData.append('phoneNumber', payload.phoneNumber as string)
    formData.append('businessWebsite', payload.business.businessWebsite as string)
    formData.append('streetAddress', payload.address.streetAddress as string)
    formData.append('streetTwoAddress', payload.address.streetTwoAddress as string)
    formData.append('city', payload.address.city as string)
    formData.append('state', payload.address.state as string)
    formData.append('zipCode', payload.address.zipCode as string)
    formData.append('timeOffset', timeZone?.rawOffset.toString() as string)
    formData.append('timezone', timeZone?.timeZoneId as string)

    if (typeof coordinates?.lat === 'number') {
        formData.append('gmapLat', String(coordinates.lat))
    }

    if (typeof coordinates?.lng === 'number') {
        formData.append('gmapLng', String(coordinates.lng))
    }

    if (payload.profileImage) {
        formData.append('profileImage', payload.profileImage)
    }
    try {
        const response = await axiosInstance.patch('/profiles/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    } catch (err) {
        console.error('Failed to update business profile:', err)
        throw err
    }
}

// Customer APIs //
export const getAllCustomers = async (
    businessId: string,
    status: string,
    keyword: string,
    s: string,
    p: PaginationType,
): Promise<CustomerProfileListType> => {
    const response = await axiosInstance.get(
        `/customer/?businessId=${businessId}&status=${status}&keyword=${keyword}&sort=${s}&page=${p.page}&pageSize=${p.pageSize}`,
    )
    return response.data
}

export const getAllCustomersMapView = async (
    businessId: string,
    startDate: string = '',
    endDate: string = '',
) => {
    const response = await axiosInstance.get(
        `/customer/map-view?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`,
    )
    return response.data
}

export const getCustomerByProfileIdAndBusinessId = async (
    profileId: string,
    businessId?: string,
): Promise<CustomerProfileType> => {
    const response = await axiosInstance.get(
        `/customer/profile?profileId=${profileId}&businessId=${businessId ? businessId : ''}`,
    )

    return response.data.content
}

// Used by
export const getEmployeeById = async (profileId: string | undefined): Promise<EmployeeType> => {
    const response = await axiosInstance.get(`/profiles/${profileId}`)

    return response.data.content
}

export const getOwnerById = async (profileId: string | undefined): Promise<EmployeeType> => {
    const response = await axiosInstance.get(`/profiles/${profileId}`)
    return response.data.content
}

// This is the api for adding a new customer //
export const inviteUser = async (params: InviteUserType) => {
    const coordinates = await fetchCoordinates(params.address as FullAddress)
    const timeZone = await fetchTimezone(coordinates as GeocodeResult)
    const response = await axiosInstance.post('/profiles/invite', {
        type: 'signup',
        email: params.email,
        data: {
            businessId: params.businessId,
            firstName: params.firstName,
            lastName: params.lastName,
            phoneNumber: params.phoneNumber,
            latitude: coordinates?.lat,
            longitude: coordinates?.lng,
            timeOffset: timeZone?.rawOffset,
            timezone: timeZone?.timeZoneId,
            role: 'CUSTOMER',
            status: USER_STATUS.ACTIVATED,
            ...params.address,
        },
    })

    return response.data
}

// This is the api for adding a new employee //
export const inviteEmployee = async (params: InviteEmployeeType) => {
    const coordinates = await fetchCoordinates(params.address as FullAddress)

    const response = await axiosInstance.post('/profiles/invite', {
        type: 'signup',
        email: params.email,
        data: {
            businessId: params.businessId,
            dateOfBirth: params.dateOfBirth,
            caLicense: params.caLicense,
            compensation: params.compensation,
            firstName: params.firstName,
            lastName: params.lastName,
            phoneNumber: params.phoneNumber,
            latitude: coordinates?.lat,
            longitude: coordinates?.lng,
            dateHired: params.dateHired,
            role: 'CREW',
            status: USER_STATUS.ACTIVATED,
            ...params.address,
        },
    })

    return response.data
}

// ^ This is the api for adding a new customer ^ //

// To delete after dev //
export const updateCustomer = async (data: CustomerProfileType) => {
    const response = await axiosInstance.post('/customer/update', {
        ...data,
        status: USER_STATUS.ACTIVATED,
    })

    return response.data
}
// ^ To delete after dev ^ //

export const addUserImage = async ({
    profileId,
    profileImage,
}: {
    profileId: string
    profileImage: File
}) => {
    // Use this after creating a new record
    const formData = new FormData()

    formData.append('profileId', profileId)
    formData.append('profileImage', profileImage)

    try {
        const response = await axiosInstance.post('/profiles/update-profile-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data
    } catch (err) {
        console.error('Failed to update business profile:', err)
        throw err
    }
}

export const CustomerCreateNewPassword = async (params: ResetPasswordType) => {
    const response = await axiosInstance.put('/profiles/customer/update', {
        password: params.newPassword,
        data: {
            isPasswordGenerated: false,
        },
    })
    return response.data
}

export const getBillingInfo = async (profileID: string): Promise<BillingInfoType> => {
    const response = await axiosInstance.get(`stripe/customer/billing-info?profileId=${profileID}`)

    return response.data.data
}

export const getSubscriptions = async (customerID: string): Promise<SubscriptionsInfoType[]> => {
    const response = await axiosInstance.get(
        `stripe/customer/subscriptions?customerId=${customerID}`,
    )

    return response.data.data
}

export const getInvoiceList = async (
    profileID: string,
    p: PaginationType,
): Promise<SubscriptionListType> => {
    const response = await axiosInstance.get(
        `stripe/customer/subscriptions/list?profileId=${profileID}&page=${p.page}&pageSize=${p.pageSize}`,
    )

    return response.data
}

export const updateBillingInfo = async (billingInfo: BillingInfoType): Promise<BillingInfoType> => {
    const response = await axiosInstance.post(`stripe/customer/billing-info`, billingInfo)

    return response.data.data
}

export const getCustomerContact = async (
    keyword?: string,
    p?: PaginationType,
    businessId?: string,
): Promise<CustomerContactType> => {
    const response = await axiosInstance.get(
        `/customer/contact?businessID=${businessId}&keyword=${keyword}&page=${p?.page}&pageSize=${p?.pageSize}`,
    )

    return response.data
}

export const updateStripeSetupLaterStatus = async (
    setupLater: StripeSetupLaterType,
): Promise<StripeSetupLaterType> => {
    const response = await axiosInstance.post(`profiles/stripe/setup-later/status`, setupLater)

    return response.data
}

export const validateStripeConnectedId = async (
    profileId: string,
    stripeId: string,
): Promise<boolean> => {
    if (!stripeId) {
        return true
    }

    const res = await getStripeConnectStatus(profileId)

    return res.status === 'restricted'
}

export const verifyOwnerRestriction = async (businessId: string): Promise<RestrictionsType> => {
    try {
        const res = await axiosInstance.get(`/stripe/verify/setup-later/${businessId}`)
        return res.data
    } catch (error) {
        throw error
    }
}

export const verifyBusinessCustomerEmailValidty = async (
    data: ValidateCustomerInviteType,
): Promise<string> => {
    const res = await axiosInstance.post(`/customer/check-email-validity`, data)

    return res.data.content
}

export const inviteExistingCustomer = async (data: ValidateCustomerInviteType) => {
    const res = await axiosInstance.post('/customer/invite-existing', data)

    return res.data
}

export const resendEmail = async (data: ValidateCustomerInviteType) => {
    const res = await axiosInstance.post('/customer/resend-email', data)

    return res.data
}

export const updateEncryptedProfileStatus = async (data: EncryptedProfileStatusType) => {
    const res = await axiosInstance.post('/public/update/encrypted-status-response', data)

    return res.data.content
}

export const getProfileWithQuoteByJobId = async (jobId: string): Promise<QuoteType> => {
    try {
        const res = await axiosInstance.get(`quotes/job-quote/${jobId}`)

        return res.data.content
    } catch (err) {
        if (err instanceof AxiosError) {
            throw new Error(err.message)
        }

        throw err
    }
}

export const chekcCustomerResponseValidity = async (
    profileId: string,
    businessId: string,
): Promise<boolean> => {
    const res = await axiosInstance.get(
        `/public/check-response-validity?profileId=${profileId}&businessId=${businessId}`,
    )

    return res.data.content
}

export const addBulkCustomer = async (
    data: AddBulkEmployeeType,
): Promise<InviteEmployeeViaCSVType> => {
    const formData = new FormData()

    formData.append('businessId', data.businessId ?? '')
    formData.append('employeeCSV', data.employeeCSV)

    try {
        const response = await axiosInstance.post('/profiles/import/customer', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        return response.data.content
    } catch (err) {
        console.error('Failed to export employees via csv:', err)
        throw err
    }
}

export const mailChimpIntegration = async (data: MailChimpIntegrationType): Promise<string> => {
    const res = await axiosInstance.post(`/profiles/mail-chimp/integration`, data)

    return res.data.content
}

export const getMailChimpCredential = async (
    businessId: string,
): Promise<MailChimpIntegrationType> => {
    const res = await axiosInstance.get(`/profiles/mail-chimp/${businessId}`)

    return res.data.content
}

export const inviteCoOwner = async (data: InviteCoOwnerType) => {
    const res = await axiosInstance.post('/profiles/invite/co-owner', data)

    return res.data.content
}

export const getBusinessCoOwners = async (
    businessId: string,
    p: PaginationType,
): Promise<BusinessOwnerLisType> => {
    const res = await axiosInstance.get(
        `/profiles/owners?businessId=${businessId}&page=${p.page}&pageSize=${p.pageSize}`,
    )

    return res.data
}

export const updatePasswordStatus = async (email: string) => {
    const res = await axiosInstance.post(`/public/update-password-status/${email}`)

    return res.data.content
}

export const checkOwnerActiveStatus = async (businessId: string) => {
    const res = await axiosInstance.get(`/profiles/business-owner-status/${businessId}`)

    return res.data.content
}

export const updateMcStatus = async (businessId: string) => {
    const res = await axiosInstance.post(`/profiles/update-mc-status/${businessId}`)

    return res.data
}

export const getMcStatus = async (businessId: string) => {
    const res = await axiosInstance.get(`/profiles/get-mc-status/${businessId}`)

    return res.data.content
}
