import { createTaxRate } from '@/api/tax'
import { createTaxRateSchema, CreateTaxRateType } from '@/api/tax/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/TextArea'
import { STRIPE_TAX_STATES, STRIPE_TAX_TYPES } from '@/constants'
import { useToast } from '@/hooks/useToast'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export const CreateTaxRate: React.FC = () => {
    const user = useViewedAsUser()

    const taxRateForm = useForm<CreateTaxRateType>({
        mode: 'onChange',
        resolver: zodResolver(createTaxRateSchema),
        defaultValues: {
            state: 'CA', // Set a default value (e.g., 'CA' for California)
            prodPct: 0,
            expensePct: 0,
            servicePct: 0,
        },
    })

    const navigate = useNavigate()
    const { toast } = useToast()

    const {
        watch,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { isValid },
    } = taxRateForm

    const queryClient = useQueryClient()

    const prodPct = watch('prodPct')
    const expencePct = watch('expensePct')
    const svcPct = watch('servicePct')

    const allZero = prodPct + expencePct + svcPct === 0

    const { mutate: createTaxMu, isPending: createLoading } = useMutation({
        mutationFn: (data: CreateTaxRateType) => createTaxRate(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['taxRates'] })
            toast({
                description: 'Tax Rate Created Successfully',
                variant: 'default',
                duration: 2000,
            })

            navigate('/settings/tax')
        },
        onError: () => {
            toast({
                description: 'Error creating tax rate',
                variant: 'destructive',
                duration: 2000,
            })
        },
    })

    const onStateChange: ChangeEventHandler<HTMLSelectElement> = (evt) => {
        setValue('state', evt.target.value, {
            shouldValidate: true,
        })
    }

    const onTaxTypeChange: ChangeEventHandler<HTMLSelectElement> = (evt) => {
        setValue('taxType', evt.target.value, {
            shouldValidate: true,
        })
    }

    const onSubmit = (data: CreateTaxRateType) => {
        createTaxMu(data)
    }

    const [prodChecked, setProdChecked] = useState(false)
    const [expenseChecked, setExpenseChecked] = useState(false)
    const [serviceChecked, setServiceChecked] = useState(false)

    const onCheckboxChange = (
        field: 'prodPct' | 'expensePct' | 'servicePct',
        setter: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
        setter((prev) => {
            const newVal = !prev
            if (!newVal) {
                setValue(field, 0)
            }
            return newVal
        })

        clearErrors(field)
        taxRateForm.trigger()
    }

    const handlePctChange = (field: 'prodPct' | 'expensePct' | 'servicePct', value: string) => {
        const val = parseFloat(value)
        setValue(field, val)
        if (val > 100) {
            setError(field, {
                message: 'Value must not exceed 100%',
            })
        } else {
            taxRateForm.trigger()
            clearErrors(field)
        }
    }

    useEffect(() => {
        setValue('state', 'CA')
        setValue('taxType', 'vat')
        setValue('businessId', user?.businessId)
    }, [setValue])

    return (
        <div>
            <BreadCrumbs titleName='Tax Management' label='Settings' />
            <Form {...taxRateForm}>
                <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardContent className='p-0'>
                            <div className='flex flex-col gap-5 p-5'>
                                <FormField
                                    control={taxRateForm.control}
                                    name='name'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='w-[670px] max-w-[30%] items-center font-sans text-base h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900'
                                                    placeholder='Name'
                                                    type='text'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className='max-w-[30%]'>
                                    <FormField
                                        control={taxRateForm.control}
                                        name='description'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Textarea
                                                        className='border border-gray-200 p-4 h-24 w-full max-w-full text-zentive-black text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                        placeholder='Description'
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <FormField
                                    control={taxRateForm.control}
                                    name='state'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <select
                                                    className='w-[670px] max-w-[30%] hover:bg-none items-center font-sans text-base pl-3 h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900 rounded'
                                                    {...field}
                                                    onChange={onStateChange}
                                                >
                                                    {Object.entries(STRIPE_TAX_STATES).map(
                                                        ([label, value]) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ),
                                                    )}
                                                </select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={taxRateForm.control}
                                    name='taxType'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <select
                                                    className='w-[670px] max-w-[30%] hover:bg-none items-center font-sans text-base pl-3 h-11 border border-zentive-gray-light disabled:bg-gray-100 text-gray-900 rounded'
                                                    {...field}
                                                    onChange={onTaxTypeChange}
                                                >
                                                    {Object.entries(STRIPE_TAX_TYPES).map(
                                                        ([label, value]) => (
                                                            <option key={value} value={value}>
                                                                {label}
                                                            </option>
                                                        ),
                                                    )}
                                                </select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className='flex flex-row gap-2 items-center relative mt-5'>
                                    <Checkbox
                                        checked={prodChecked}
                                        onCheckedChange={() =>
                                            onCheckboxChange('prodPct', setProdChecked)
                                        }
                                        className=''
                                    />
                                    <FormField
                                        control={taxRateForm.control}
                                        name='prodPct'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                        placeholder='Products'
                                                        type='number'
                                                        {...field}
                                                        disabled={!prodChecked} // Disable based on checkbox state
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key === '-' ||
                                                                e.key === '+' ||
                                                                e.key === 'e' ||
                                                                e.key === 'E'
                                                            ) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            handlePctChange(
                                                                'prodPct',
                                                                e.target.value,
                                                            )
                                                        }}
                                                    />
                                                </FormControl>
                                                <div className='absolute mt-2'>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <div className='flex flex-row gap-2 items-center relative mt-5'>
                                    <Checkbox
                                        checked={expenseChecked}
                                        onCheckedChange={() =>
                                            onCheckboxChange('expensePct', setExpenseChecked)
                                        }
                                    />
                                    <FormField
                                        control={taxRateForm.control}
                                        name='expensePct'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                        placeholder='Expenses'
                                                        type='number'
                                                        {...field}
                                                        disabled={!expenseChecked} // Disable based on checkbox state
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key === '-' ||
                                                                e.key === '+' ||
                                                                e.key === 'e' ||
                                                                e.key === 'E'
                                                            ) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            handlePctChange(
                                                                'expensePct',
                                                                e.target.value,
                                                            )
                                                        }}
                                                    />
                                                </FormControl>
                                                <div className='absolute mt-2'>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <div className='flex flex-row gap-2 items-center mt-5'>
                                    <Checkbox
                                        checked={serviceChecked}
                                        onCheckedChange={() =>
                                            onCheckboxChange('servicePct', setServiceChecked)
                                        }
                                    />
                                    <FormField
                                        control={taxRateForm.control}
                                        name='servicePct'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='font-sans pl-1 text-base h-11 border border-zentive-gray-light text-gray-900 disabled:bg-gray-100 rounded-sm'
                                                        placeholder='Services'
                                                        type='number'
                                                        {...field}
                                                        disabled={!serviceChecked} // Disable based on checkbox state
                                                        onKeyDown={(e) => {
                                                            if (
                                                                e.key === '-' ||
                                                                e.key === '+' ||
                                                                e.key === 'e' ||
                                                                e.key === 'E'
                                                            ) {
                                                                e.preventDefault()
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            handlePctChange(
                                                                'servicePct',
                                                                e.target.value,
                                                            )
                                                        }}
                                                    />
                                                </FormControl>
                                                <div className='absolute mt-2'>
                                                    <FormMessage />
                                                </div>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='mt-5 py-5 md:py-0 bg-zentive-green-light left-0 w-full md:h-[75px] rounded-b-[8px] flex items-center justify-end px-[26px] gap-[12px]'>
                                <Button
                                    className='w-[95px] h-[43px] border border-zentive-green-dark text-zentive-green-dark hover:text-white font-semibold bg-white rounded-[5px] text-[16px] hover:bg-zentive-green-dark'
                                    disabled={createLoading}
                                    onClick={() => navigate('/settings/tax')}
                                    type='button'
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className='w-[95px] h-[43px] text-white font-semibold bg-zentive-green-dark rounded-[5px] text-[16px] hover:bg-zentive-green-medium'
                                    disabled={!isValid || createLoading || allZero}
                                    type='submit'
                                >
                                    Save
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </form>
            </Form>
        </div>
    )
}
