import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    CreateDiscountType,
    CreateInvoiceDiscountType,
    DiscountListType,
    DiscountRecordType,
} from './schema'

export const createDiscount = async (discount: CreateDiscountType) => {
    const res = await axiosInstance.post(`/stripe/discounts/subscriptions/create`, discount)

    return res.data
}

export const createInvoiceDiscount = async (discount: CreateInvoiceDiscountType) => {
    const res = await axiosInstance.post(`/stripe/discounts/invoices/create`, discount)

    return res.data
}

export const getDiscountList = async (
    keyword: string,
    p: PaginationType,
    businessId: string,
    usageType: string,
): Promise<DiscountListType> => {
    const { page, pageSize } = p
    // const res = await axiosInstance.get(`/stripe/discounts/get?keyword=${keyword}&page=${page}&pageSize=${pageSize}`)
    const res = await axiosInstance.get(`/stripe/discounts/get`, {
        params: {
            keyword: keyword,
            page: page,
            pageSize: pageSize,
            businessId: businessId,
            usageType: usageType,
        },
    })

    return res.data
}

export const deleteDiscount = async (couponCode: string): Promise<string> => {
    const res = await axiosInstance.post(`/stripe/discounts/delete/${couponCode}`)

    return res.data.content
}

export const checkCouponCode = async (
    couponCode: string,
    subscriptionId: string,
    usageType: string,
): Promise<string> => {
    const res = await axiosInstance.get(`/stripe/discounts/check-code-status`, {
        params: {
            discountCode: couponCode,
            subId: subscriptionId,
            usageType: usageType,
        },
    })

    return res.data.content
}

export const getCouponCode = async (
    couponCode: string,
    usageType: string,
): Promise<DiscountRecordType> => {
    const res = await axiosInstance.get(`/stripe/discounts/get-code`, {
        params: {
            code: couponCode,
            usageType: usageType,
        },
    })

    return res.data.content
}
