import mailChompLogo from '@/assets/private/mail-chimp-logo.svg'
import { MailChimpIntegrationType } from '@/api/profile/schema'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { getMcStatus, updateMcStatus } from '@/api/profile'
import Spinner from '@/components/Spinner'
import { USER_STATUS } from '@/constants'
import { Button } from '@/components/Button'
import { AxiosError } from 'axios'
import { useToast } from '@/hooks/useToast'

interface LinkedMailChimpCredential {
    mcCreds: MailChimpIntegrationType
}

const LinkedMailChimpCredential: React.FC<LinkedMailChimpCredential> = ({ mcCreds }) => {
    const user = useViewedAsUser()
    const { toast } = useToast()
    const queryClient = useQueryClient()

    const { data: status, isLoading } = useQuery({
        queryKey: ['mcStatus', user],
        queryFn: () => getMcStatus(user?.businessId),
    })

    const { mutate: updateMcMu } = useMutation<unknown, AxiosError, string>({
        mutationFn: updateMcStatus,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['mcStatus'] })
            toast({
                description: 'MailChimp Status Updated Successfully',
            })
        },
        onError: () => {
            toast({
                description: 'There was an error updating MailChimp status',
                variant: 'destructive',
            })
        },
    })

    return (
        <div className='bg-white h-full flex flex-col items-center justify-center gap-10 p-10'>
            {isLoading ? (
                <Spinner variant='normal' className='h-10 w-10' />
            ) : (
                <>
                    <img src={mailChompLogo} />
                    <h1 className='font-bold text-zentive-green-dark text-2xl'>
                        MailChimp Account is Linked
                    </h1>

                    <div className='w-full font-medium text-zentive-gray-dark'>
                        <p className='mb-3'>Account Information</p>
                        <div className='flex flex-col gap-5'>
                            <div className='flex flex-col border border-zentive-gray-light h-[5rem] w-full p-4'>
                                <h1 className='text-zentive-gray-dark'>API key</h1>
                                <p className='text-zentive-gray-medium text-base'>
                                    {mcCreds.apiKey}
                                </p>
                            </div>
                            <div className='flex flex-col border border-zentive-gray-light h-[5rem] w-full p-4'>
                                <h1 className='text-zentive-gray-dark'>Audience ID</h1>
                                <p className='text-zentive-gray-medium text-base'>
                                    {mcCreds.listId}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row justify-start w-full -mt-10'>
                        {status === USER_STATUS.ACTIVATED ? (
                            <Button
                                variant='link'
                                className='text-zentive-red-500'
                                onClick={() => updateMcMu(user?.businessId)}
                            >
                                Disconnect Account
                            </Button>
                        ) : (
                            <Button
                                variant='link'
                                className='text-zentive-green-dark'
                                onClick={() => updateMcMu(user?.businessId)}
                            >
                                Reconnect Account
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default LinkedMailChimpCredential
