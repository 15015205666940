import { createDiscount } from '@/api/discounts'
import { CreateDiscountType, createDiscountSchema } from '@/api/discounts/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { OneJobDatePicker } from '@/components/OneOffJobDatePicker'
import { DISCOUNT_TYPE, ISO_8601, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { timezoneAtom } from '@/store/auth'
import { formatToUTCWithOffset } from '@/utils/time'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { SelectSingleEventHandler } from 'react-day-picker'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Elligibility } from './Eligibility'
import useViewedAsUser from '@/hooks/useViewedAsUser'

export const CreateSubscriptionDiscount = () => {
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')

    const user = useViewedAsUser()

    const tz = useAtomValue(timezoneAtom)

    const navigate = useNavigate()

    const { toast } = useToast()

    const queryClient = useQueryClient()

    const methods = useForm<CreateDiscountType>({
        mode: 'onChange',
        resolver: zodResolver(createDiscountSchema),
        defaultValues: {
            discountType: DISCOUNT_TYPE.percentage,
            businessId: user?.businessId,
        },
    })

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
        setError,
        clearErrors,
    } = methods

    const discountType = watch('discountType')
    const startVal = watch('startDate')
    const endVal = watch('endDate')
    const subTypes = watch('subTypes')
    const amount = watch('amount')

    const { mutate: createDiscountMu, isPending: isLoading } = useMutation<
        string,
        AxiosError,
        CreateDiscountType
    >({
        mutationFn: createDiscount,
        onSuccess: () => {
            toast({
                description: 'Coupon Code Created Successfully',
                duration: 2000,
            })

            queryClient.invalidateQueries({ queryKey: ['discounts'] })
            navigate('/settings/discounts')
        },
        onError: () => {
            toast({
                description: 'Coupon Code already exists!',
                variant: 'destructive',
            })
        },
    })

    const setStartDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedStart = formatToUTCWithOffset(
            newValueDate,
            tz?.timeZoneId as string,
            ISO_8601_WITH_UTC_OFFSET,
        )

        setStart(formattedStart)
    }

    const setStartDate: SelectSingleEventHandler = (date: Date | undefined) =>
        setStartDateTime(date)

    const setEndDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedEnd = formatToUTCWithOffset(
            newValueDate,
            tz?.timeZoneId as string,
            ISO_8601_WITH_UTC_OFFSET,
        )

        setEnd(formattedEnd)
    }

    const setEndDate: SelectSingleEventHandler = (date: Date | undefined) => setEndDateTime(date)

    useEffect(() => {
        if (dayjs(start).isAfter(dayjs(end))) {
            setError('startDate', { message: 'Start date cannot be greater than the end date' })
            setError('endDate', { message: 'End date cannot be lower than the start date' })
        } else {
            clearErrors(['startDate', 'endDate'])
        }
    }, [start, end])

    const onSubmit = (data: CreateDiscountType) => {
        createDiscountMu(data)
    }

    useEffect(() => {
        setValue('startDate', start)
        setValue('endDate', end)
    }, [start, end])

    useEffect(() => {
        if (discountType === 'percentage' && amount > 100) {
            setError('amount', { message: '100% is the maximum discount percentage.' })
        }
    }, [discountType, amount, errors])

    return (
        <>
            <BreadCrumbs titleName='Add Discount' />
            <div className='flex flex-col gap-5 mt-10 bg-white p-5 items-center justify-center'>
                <div className='flex flex-col gap-5 mx-auto max-w-[75%]'>
                    <Form {...methods}>
                        <div className='grid grid-cols-2 gap-5 w-full'>
                            <FormField
                                control={control}
                                name='discountCode'
                                render={({ field }) => (
                                    <FormItem className='col-span-1'>
                                        <FormControl>
                                            <Input
                                                className='w-full'
                                                placeholder='Discount Code*'
                                                type='text'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={control}
                                name='name'
                                render={({ field }) => (
                                    <FormItem className='col-span-1'>
                                        <FormControl>
                                            <Input
                                                className='w-full'
                                                placeholder='Discount Name*'
                                                type='text'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            <div className='flex flex-col'>
                                <div className='flex flex-row'>
                                    <FormField
                                        control={control}
                                        name='amount'
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormControl>
                                                    <Input
                                                        className='w-full w-[15rem]'
                                                        placeholder={`Discount (${
                                                            discountType
                                                                ? discountType ===
                                                                  DISCOUNT_TYPE.percentage
                                                                    ? '%'
                                                                    : '$'
                                                                : ''
                                                        })*`}
                                                        type='number'
                                                        {...field}
                                                        onChange={(e: any) =>
                                                            field.onChange(Number(e.target.value))
                                                        }
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                    <span className='py-2 ml-2 col-span-1'>
                                        <select
                                            onChange={(e) =>
                                                setValue('discountType', e.target.value)
                                            }
                                        >
                                            <option value={DISCOUNT_TYPE.percentage}>%</option>
                                            <option value={DISCOUNT_TYPE.fixed}>$</option>
                                        </select>
                                    </span>
                                </div>
                                {errors.amount && (
                                    <p className='text-red-500'>{errors.amount.message}</p>
                                )}
                            </div>
                            <FormField
                                control={control}
                                name='usageLimit'
                                render={({ field }) => (
                                    <FormItem className='col-span-1'>
                                        <FormControl>
                                            <Input
                                                className='w-full'
                                                placeholder='Usage Limit*'
                                                type='number'
                                                {...field}
                                                onChange={(e) =>
                                                    field.onChange(Number(e.target.value))
                                                }
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <Elligibility />
                        {errors.subTypes && (
                            <p className='text-red-500'>{errors.subTypes.message}</p>
                        )}
                        <p className='font-bold text-base text-zentive-green-dark'>
                            Discount Duration
                        </p>
                        <div className='grid grid-cols-2 gap-10'>
                            <div className='flex flex-col w-[18.5rem] col-span-1'>
                                <OneJobDatePicker
                                    setJobDate={setStartDate}
                                    jobDate={start}
                                    label='Start Date'
                                    showOutsideDates={false}
                                />
                                {errors.startDate && (
                                    <p className='text-red-500'>{errors.startDate.message}</p>
                                )}
                            </div>
                            <div className='flex flex-col w-[18.5rem] col-span-1'>
                                <OneJobDatePicker
                                    setJobDate={setEndDate}
                                    jobDate={end}
                                    label='End Date'
                                    showOutsideDates={false}
                                />
                                {errors.endDate && (
                                    <p className='text-red-500'>{errors.endDate.message}</p>
                                )}
                            </div>
                        </div>
                        <div className='flex flex-row items-end justify-end gap-5'>
                            <Button
                                variant={'outline'}
                                className='w-[8rem]'
                                onClick={() => navigate(-1)}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    isLoading ||
                                    !startVal ||
                                    !endVal ||
                                    subTypes?.length === 0 ||
                                    !subTypes ||
                                    Object.keys(errors).length > 0 // Disable if there are any form errors
                                }
                                className='w-[8rem]'
                                onClick={handleSubmit(onSubmit)}
                                type='submit'
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
