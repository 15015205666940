import { checkCouponCode, getCouponCode } from '@/api/discounts'
import { DiscountRecordType } from '@/api/discounts/schema'
// import { DiscountRecordType } from '@/api/discounts/schema'
import { QuoteType } from '@/api/quoting/schema'
import { Button } from '@/components/Button'
import {
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
} from '@/components/Dialog'
import { Input } from '@/components/Input'
import { COUPON_ERROR_MESSAGES, DISCOUNT_TYPE, STRIPE_COUPON_USAGETYPE } from '@/constants'
import { useToast } from '@/hooks/useToast'
import { jobInvoiceDiscountAtom } from '@/store/job'
import { DialogClose } from '@radix-ui/react-dialog'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useSetAtom } from 'jotai'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

const AddDiscountDialog = ({
    setDiscount,
    subTotal,
    // handleDiscountTypeChange,
}: {
    setDiscount: (discount: {
        percentage: number
        amount: number
        isPercent: boolean
        totalAfterDiscount: number
    }) => void
    subTotal: number
    // handleDiscountTypeChange: (isPercent: boolean) => void
}) => {
    const [error, setErr] = useState<string>('')
    const [discountCode, setDiscountCode] = useState<string>('')
    const setJobInvoiceDiscount = useSetAtom(jobInvoiceDiscountAtom)
    const [discount, setDiscountInside] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0,
        discountId: '',
    })
    const { setValue } = useFormContext<QuoteType>()
    const { toast } = useToast()

    const resetDiscountState = () => {
        setDiscountInside({
            percentage: 0,
            amount: 0,
            isPercent: false,
            totalAfterDiscount: 0,
            discountId: '',
        })
    }

    const { mutate: applyDiscountMu } = useMutation<DiscountRecordType, AxiosError, string>({
        mutationFn: (code) => getCouponCode(code, STRIPE_COUPON_USAGETYPE.Invoice),
        onSuccess: (data: DiscountRecordType) => {
            if (data.discountType === DISCOUNT_TYPE.percentage) {
                const discountAmount = (subTotal * data.amount) / 100
                const totalAfterDiscount = subTotal - discountAmount
                setDiscountInside({
                    isPercent: true,
                    percentage: data.amount,
                    amount: discountAmount,
                    totalAfterDiscount: totalAfterDiscount,
                    discountId: data.discountId,
                })
            } else {
                setDiscountInside((prevState) => ({
                    ...prevState,
                    isPercent: false,
                    amount: data.amount,
                    totalAfterDiscount: subTotal - data.amount,
                    discountId: data.discountId,
                }))
            }
            setValue('isDiscountPercent', data.discountType === DISCOUNT_TYPE.percentage)
            setValue('discount', data.amount)
        },
        onError: (err: AxiosError) => {
            console.log(err)
        },
    })

    const { mutate: verifyDiscountMu } = useMutation<string, AxiosError, string>({
        mutationFn: (code) => checkCouponCode(code, '', STRIPE_COUPON_USAGETYPE.Invoice),
        onSuccess: (couponStatus: string, code: string) => {
            if (couponStatus != '') {
                if (couponStatus === COUPON_ERROR_MESSAGES.NOT_EXIST) {
                    setErr('Coupon Code does not Exist')
                } else if (couponStatus === COUPON_ERROR_MESSAGES.USAGE_EXCEED) {
                    setErr('Coupon Code already exceeds its Usage Limit')
                } else if (couponStatus === COUPON_ERROR_MESSAGES.EXPIRED) {
                    setErr('Coupon Code is expired')
                } else if (couponStatus === COUPON_ERROR_MESSAGES.AVAILABLE) {
                    setErr('')
                    applyDiscountMu(code)
                }
            } else {
                setErr('')
                applyDiscountMu(code)
            }
        },
        onError: (err: AxiosError) => {
            console.log(err)
        },
    })

    const handleSaveDiscount = () => {
        if (discount.amount < subTotal) {
            setDiscount(discount)
            resetDiscountState()
            setJobInvoiceDiscount(discount.amount)
            setValue('discountId', discount.discountId)
        } else {
            toast({
                description: 'Discount amount must not be equal or greater than the subtotal.',
                variant: 'destructive',
            })
        }
    }

    const handleDiscountCancel = () => {
        setValue('discountId', '')
        setValue('discount', 0)
        setJobInvoiceDiscount(0)
    }

    return (
        <DialogPortal>
            <DialogOverlay>
                <DialogContent className='max-w-[400px] w-[400px] max-h-[280px] h-[280px] rounded-md '>
                    <DialogHeader className='m-6'>
                        <DialogTitle className='mb-2'>
                            <div className='text-2xl font-semibold text-left mb-6'>
                                Add Discount
                            </div>
                        </DialogTitle>
                        <DialogDescription className='no-scrollbar'>
                            <div className='flex flex-col gap-1 justify-center'>
                                {/* <Input
                                    className='w-full flex focus:border-r-0 justify-center items-center  font-sans text-base rounded-tr-none rounded-br-none h-11 border border-black border-r-0 disabled:bg-gray-100 text-gray-900'
                                    placeholder='Discount'
                                    name='rate'
                                    type='number'
                                    value={
                                        discount.isPercent == true
                                            ? discount.percentage
                                            : discount.amount
                                    }
                                    onChange={(val) =>
                                        handleOnDiscountChange(parseFloat(val.target.value))
                                    }
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === '-' ||
                                            e.key === '+' ||
                                            e.key === 'e' ||
                                            e.key === 'E'
                                        ) {
                                            e.preventDefault()
                                        }
                                    }}
                                /> */}

                                <Input
                                    className='w-full flex justify-center items-center  font-sans text-base rounded-tr-none rounded-br-none h-11 border border-black disabled:bg-gray-100 text-gray-900'
                                    placeholder='Discount'
                                    name='rate'
                                    type='text'
                                    value={discountCode}
                                    onChange={(e) => {
                                        setDiscountCode(e.target.value)
                                        setTimeout(() => {
                                            verifyDiscountMu(e.target.value)
                                        }, 500)
                                    }}
                                />
                                <p className='text-left text-zentive-red-500'>{error}</p>

                                {/* <select
                                    name='row'
                                    className='w-[20%] focus:outline-none font-sans-pro text-base pl-3 border border-black disabled:bg-gray-100 text-gray-900 rounded-r-sm'
                                    onChange={handleSelectChange}
                                >
                                    <option>$</option>
                                    <option>%</option>
                                </select> */}
                            </div>
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter className='absolute self-end py-4 px-6 w-full rounded-b-md justify-center items-center bg-zentive-gray-light'>
                        <div className='flex flex-row w-full justify-center gap-x-4 items-center'>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-[50%]'
                                    onClick={handleDiscountCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogClose>
                            <DialogClose asChild>
                                <Button
                                    type='button'
                                    className='w-[50%]'
                                    disabled={
                                        discount.amount == 0 && discount.percentage == 0
                                            ? true
                                            : false
                                    }
                                    onClick={handleSaveDiscount}
                                >
                                    Save
                                </Button>
                            </DialogClose>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </DialogOverlay>
        </DialogPortal>
    )
}

export default AddDiscountDialog
