import { SubscriptionType } from '@/api/subscription/schema'
import { Table, TableBody, TableCell, TableRow } from '@/components/Table'
import { displayCurrency } from '@/utils/helper'

const FeatureTable = ({ tableData }: { tableData: Array<SubscriptionType> }) => {
    return (
        <Table className='w-full'>
            <TableBody>
                <TableRow
                    key={'p[rice'}
                    className='divide-x divide-gray-200 hover:bg-none odd:bg-white even:bg-zentive-green-light'
                >
                    <TableCell className='w-[50%] text-[16px] pl-[26px] '>Price</TableCell>
                    {tableData?.map((s) => (
                        <TableCell className='w-[160px] text-center '>
                            <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                                {displayCurrency((s?.price ?? 0) / 100)}
                            </p>
                        </TableCell>
                    ))}
                </TableRow>

                <TableRow
                    key={'set-up-fee'}
                    className='divide-x divide-gray-200 hover:bg-none odd:bg-white even:bg-zentive-green-light'
                >
                    <TableCell className='w-[50%] text-[16px] pl-[26px] '>Set Up Fee</TableCell>
                    <TableCell className='w-[160px] text-center '>
                        <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                            <>
                                <span>$50.00</span>
                                <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                            </>
                        </p>
                    </TableCell>
                    <TableCell className='w-[160px] text-center '>
                        <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                            <>
                                <span>$50.00</span>
                                <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                            </>
                        </p>
                    </TableCell>
                    <TableCell className='w-[160px] text-center '>
                        <p className='relative inline-block text-[16px] text-zentive-green-dark'>
                            <>
                                <span>$50.00</span>
                                <span className='absolute h-0.5 bg-zentive-red-dark w-full transform -rotate-45 top-1/2 left-0'></span>
                            </>
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
export default FeatureTable
