import { signUpGalleryAtom } from '@/store/auth'
import { useAtomValue } from 'jotai'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { SubscriptionType } from '@/api/subscription/schema'
import { getPricingById, getPricingIntervalById } from '@/api/subscription'
import ChangePlanButton from './ChangePlanButton'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import Spinner from '@/components/Spinner'

interface ActionButtonProps {
    interval: string
}

const PlanButtons = ({
    plan,
    interval,
    currentPlan,
    isChangePlan,
}: {
    plan: SubscriptionType
    interval: string
    currentPlan: SubscriptionType
    isChangePlan: string
}) => {
    const { data: intervalPlan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['pricingInterval', plan, interval],
        queryFn: () =>
            getPricingIntervalById({
                planId: plan.subscriptionTypeId as string,
                interval: currentPlan?.interval as string,
            }),
    })

    const getChangePlanType = () => {
        const currentPricing = currentPlan?.price ?? 0
        const planPricing = intervalPlan?.price ?? plan.price ?? 0

        if (plan.interval === interval && currentPlan?.interval === interval) {
            if (currentPlan.name === plan.name) {
                return 'Current Plan'
            } else {
                return planPricing / 100 > currentPricing ? 'Upgrade' : 'Downgrade'
            }
        } else {
            if (currentPlan.name === plan.name) {
                return interval === 'month' ? 'Switch to Monthly' : 'Switch to Yearly'
            } else {
                console.log(planPricing / 100, currentPricing)
                return planPricing / 100 > currentPricing ? 'Upgrade' : 'Downgrade'
            }
        }
    }

    return (
        <div key={plan.subscriptionTypeId}>
            <ChangePlanButton
                plan={plan}
                changePlanType={getChangePlanType()}
                isChangePlan={isChangePlan}
            />
        </div>
    )
}

const ActionButton: React.FC<ActionButtonProps> = ({ interval }) => {
    const selected = useAtomValue(signUpGalleryAtom)
    const params = new URLSearchParams(window.location.search)
    // const subscriptionTypeId = params.get('subscriptionTypeId')

    const profileID = params.get('profileID')

    const {
        data: businessProfile,
        isError: isBusinessProfileError,
        isSuccess: isBusinessProfileSuccess,
        error: businessProfileError,
    } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        enabled: !!profileID,
        queryKey: ['businessProfile', profileID],
        queryFn: () => getBusinessProfile(profileID as string),
    })

    const {
        data: currentPlan,
        isError: isPricingByIdError,
        error: pricingByIdError,
    } = useQuery<unknown, AxiosError, SubscriptionType>({
        enabled: !!businessProfile?.business?.subscriptionTypeId && isBusinessProfileSuccess,
        queryKey: ['pricingById', businessProfile?.business?.subscriptionTypeId],
        queryFn: () => getPricingById(businessProfile?.business.subscriptionTypeId as string),
    })

    const hasError = isBusinessProfileError || isPricingByIdError

    if (hasError) {
        const errorMessage =
            (businessProfileError || pricingByIdError)?.message || 'An error occurred'
        return <p className='text-[16px] text-zentive-red-dark font-semibold'>{errorMessage}</p>
    }

    if (!currentPlan) return <Spinner size='50' variant='normal' />

    const renderPlanButtons = () => {
        return selected
            ?.sort((a, b) => {
                const order = ['Starter', 'Standard', 'Growth Pro']
                return order.indexOf(a.name as string) - order.indexOf(b.name as string)
            })
            ?.map((plan) => (
                <PlanButtons
                    key={plan.subscriptionTypeId}
                    plan={plan}
                    interval={interval}
                    currentPlan={currentPlan}
                    isChangePlan={businessProfile?.business?.isChangePlan as string}
                />
            ))
    }

    return (
        <div className='flex justify-end w-full'>
            <div className='inline-flex xl:gap-x-[22px] 2xl:gap-x-[85px] w2xl:gap-x-[110px] 3xl:gap-x-[195px] 4xl:gap-x-[408px]'>
                {renderPlanButtons()}
            </div>
        </div>
    )
}

export default ActionButton
