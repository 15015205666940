import { Button } from '@/components/Button'
import { Card, CardFooter } from '@/components/Card'
import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from '@/hooks/useToast'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { getNextBusinessInvoiceNumber, saveInvoice } from '@/api/invoicing'
import { InvoiceType, invoiceSchema } from '@/api/invoicing/schema'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { customerInvoiceAtom, invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import {
    BREADCRUMBS_PADDING_STYLE,
    DEFAULT_PRODUCT_SERVICE_GROUP_ITEM,
    DEFAULT_QUOTE_TAX_VALUE,
    DEFAULT_QUOTE_VALUE,
} from '@/constants'
import { startTransition, useEffect } from 'react'
import { jobGenerateInvoiceAtom, selectedJobForInvoiceAtom } from '@/store/job'
import AddInvoiceHeader from './CardHeader'
import AddInvoiceContent from './CardContent'
import { businessIdAtom } from '@/store/auth'

const AddInvoice = () => {
    const navigate = useNavigate()

    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)

    const [customer, setCustomer] = useAtom(customerInvoiceAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const setGenerateInvoiceData = useSetAtom(jobGenerateInvoiceAtom)
    const [jobForInvoicing, setJobForInvoicing] = useAtom(selectedJobForInvoiceAtom)

    const businessId = useAtomValue(businessIdAtom)

    const { data: nextInvoiceNumber, isSuccess } = useQuery({
        enabled: !!businessId,
        queryKey: ['nextEmpNumber', businessId],
        queryFn: () => getNextBusinessInvoiceNumber(businessId),
    })

    const methods = useForm<InvoiceType>({
        mode: 'onSubmit',
        resolver: zodResolver(invoiceSchema),
        defaultValues: {
            productServiceGroup: [{ ...DEFAULT_PRODUCT_SERVICE_GROUP_ITEM }],
            expense: [],
            grossIncome: 0,
            netIncome: 0,
            sharedNotes: '',
        },
    })

    // Mutation for saving invoice
    const { mutate: saveInvoiceMu, isPending } = useMutation<ResponseType, AxiosError, InvoiceType>(
        {
            mutationKey: ['SaveInvoice'],
            mutationFn: (data) => saveInvoice(data),
            onSuccess: () => {
                toast({
                    description: 'Successfully Saved and Sent',
                    variant: 'default',
                })
                setCustomer(null)
                setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                setInvoiceContent(DEFAULT_QUOTE_VALUE)
                setGenerateInvoiceData(null)
                navigate('/financial-management/invoicing')
            },
            onError: (err) => console.error('error! ', err),
        },
    )

    const { handleSubmit, watch, setValue } = methods
    const watchedFields = watch()

    // Update form values with taxes on change
    useEffect(() => {
        startTransition(() => {
            setInvoiceContent(DEFAULT_QUOTE_VALUE)
            setGenerateInvoiceData(null)
            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
        })
    }, [])

    // Handle form submission
    const onSubmit = (data: InvoiceType) => {
        if ((watchedFields?.totalAmount ?? 0) < 0.5) {
            toast({
                title: 'Error! Invalid Amount!',
                description: 'Total Amount must be equal or higher than $0.5',
                variant: 'destructive',
            })
            return
        }

        // data.productServiceGroup?.forEach((item) => {
        //     item.cost = item?.cost ? item?.cost * 100 : 0
        // })
        
        data.jobID = jobForInvoicing?.jobId as string
        if (!jobForInvoicing) {
            data.businessId = businessId
        }
        saveInvoiceMu(data)
    }

    useEffect(() => {
        startTransition(() => {
            setValue('taxName', taxes.name)
            setValue('productTax', taxes.productTax)
            setValue('serviceTax', taxes.serviceTax)
            setValue('expenseTax', taxes.expenseTax)
        })
    }, [taxes])

    useEffect(() => {
        if (isSuccess && nextInvoiceNumber) {
            setValue('businessInvoiceNumber', nextInvoiceNumber)
        }
    }, [isSuccess, nextInvoiceNumber, customer?.profile?.profileId])

    return (
        <div className={BREADCRUMBS_PADDING_STYLE}>
            <BreadCrumbs titleName='Add Invoice' />
            <div className='mt-4 w-full'>
                <Form {...methods}>
                    <Card className='border-0 border-t-8 border-zentive-green-dark'>
                        <AddInvoiceHeader invoiceNumber={nextInvoiceNumber as number} />
                        <AddInvoiceContent />
                        <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-gray-200'>
                            <div className='flex space-x-4 mt-6'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-full'
                                    onClick={() => {
                                        navigate('/financial-management/invoicing')
                                        setInvoiceContent(DEFAULT_QUOTE_VALUE)
                                        setGenerateInvoiceData(null)
                                        setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                                        setJobForInvoicing(null)
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    onClick={handleSubmit(onSubmit)}
                                    className='w-full disabled:bg-zentive-gray-medium'
                                    disabled={
                                        isPending ||
                                        watchedFields.productServiceGroup?.some(
                                            (service) => service?.productServiceId === '',
                                        )
                                    }
                                >
                                    Save and Send Invoice
                                </Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Form>
            </div>
        </div>
    )
}

export default AddInvoice
