import { Card, CardContent } from '@/components/Card'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import emptyPlaceholder from '@/assets/private/empty-expenses.png'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { RequestServiceType } from '@/api/service/schema'
import { getRequestServices } from '@/api/service'
import { useAtomValue } from 'jotai'
import { businessIdAtom } from '@/store/auth'
// import RefundTable from './RefundTable'

const CPRefund = () => {
    const businessId = useAtomValue(businessIdAtom)

    const { data: services, isLoading } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType', businessId],
        queryFn: () => getRequestServices(businessId),
    })

    if (isLoading) {
        return <></>
    }

    return (
        <div className={BREADCRUMBS_PADDING_STYLE}>
            <BreadCrumbs titleName='Refunds' />
            <Card className='mt-5 w-full' style={{ overflowY: 'auto' }}>
                <CardContent className='flex flex-col gap-5 p-4 pt-[36px] overflow-x-scroll md:overflow-x-auto items-center justify-center'>
                    {/* <RefundTable /> */}
                    <img src={emptyPlaceholder} className='h-[20rem] w-[28rem]' />
                    <p className='text-zentive-green-dark text-2xl font-bold'>
                        For refund requests
                    </p>
                    <p className='flex flex-row gap-1 text-center text-lg'>
                        Please email
                        <p className='text-zentive-green-dark font-semibold'>
                            {services?.ownerEmail}
                        </p>
                        .
                    </p>
                    <p className='flex flex-col text-center text-lg pb-10'>
                        Be sure to include your Job #, reason for the refund, and the requested
                        amount in your email.
                    </p>
                </CardContent>
            </Card>
        </div>
    )
}

export default CPRefund
